import {scrollToTop} from '@/globals';
import {MailerLiteApi, MailerLitePayLoadModel, ParkListingApi, ParkListingService} from '@/sdk';
import {FilterModel, ListingFilterViewModel} from '@/sdk/models/common/filter.model';
import {Component} from 'nuxt-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import BaseFormComponent from '~/components/vuetify/base-form/base-form';
import {AppVersion} from '~/sdk/app.config';
import BaseDividerComponent from '~/components/shared/base-divider/base-divider.component';

@Component({
    components: {
        'base-divider': BaseDividerComponent,
    },
})
export default class ClientFooterComponent extends VueWrapper {
    public $refs!: {
        footerBaseform: any;
    };
    public parkListingApi: ParkListingApi = new ParkListingApi();
    public filter: FilterModel = new FilterModel();
    public listingFilter = new ListingFilterViewModel();
    public parkListingSrv = new ParkListingService();
    public tempEmail: string = '';

    public states = [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana Home',
    ];
    public links = [
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming',
    ];
    public solutions = ['Park Sites', 'Advertise', 'Contacts CRM', 'Sold Parks', 'My Parks', 'Professionals'];
    public companies = [
        {title: 'Contact Us', link: 'contact-us'},
        {title: 'Our Team', link: 'our-team'},
        {title: 'Help Center', link: 'https://help.mhparks.com'},
        {title: 'Terms', link: 'terms'},
        {title: 'Privacy Policy', link: 'privacy-policy'},
        {title: 'Disclaimer', link: 'disclaimer'},
    ];
    public popularSearch = [
        {title: 'Mobile Home Park Records', link: 'learn-more'},
        {title: 'Mobile Home Park Sites', link: 'solutions/park-sites-solution'},
        {title: 'Mobile Home Park Advertising', link: 'solutions/advertise-solution'},
        {title: 'Mobile Home Parks for Sale', link: 'park-for-sale-ads'},
        {title: 'Mobile Home Park Classified Ads', link: 'classified-ads'},
        {title: 'Mobile Home Park Professionals', link: 'professionals'},
    ];
    // public popularSearches = [
    //     {
    //         title: 'Mobile Home Park Sites',
    //         to: {name: 'park-sites-solution'},
    //         route: () => {
    //             this.$router.replace({name: 'Park Sites'});
    //         },
    //     },
    //     {
    //         title: 'Mobile Homes for Sale',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-homes-for-sale'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-homes-for-sale'}});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Brokers',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-home-park-brokers'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-home-park-brokers'}});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Lenders',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-home-park-lenders'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-home-park-lenders'}});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Insurance',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-home-park-insurance-agents'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-home-park-insurance-agents'}});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Investment Coaches',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-home-park-investment-coach'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-home-park-investment-coach'}});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park List',
    //         to: {name: 'park-sites-solution'},
    //         route: () => {
    //             this.$router.replace({name: 'Park Sites'});
    //         },
    //     },
    //     {
    //         title: 'Learn to Invest in Mobile Home Parks',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-home-park-investment-coach'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-home-park-investment-coach'}});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Real Estate Agents',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: ''}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-home-park-brokers'}});
    //         },
    //     },
    //     {
    //         title: 'Why Advertise with MHParks.com',
    //         to: {name: 'advertising'},
    //         route: () => {
    //             this.$router.push({name: 'Advertise'});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Sale Trends',
    //         to: {name: 'sold-parks-solution'},
    //         route: () => {
    //             this.$router.push({name: 'Sold Parks'});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Investing',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-home-park-investment-coach'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-home-park-investment-coach'}});
    //         },
    //     },
    //     {
    //         title: 'Mobile Home Park Appraisals',
    //         to: {name: 'sold-parks-solution'},
    //         route: () => {
    //             this.$router.push({name: 'Sold Parks'});
    //         },
    //     },
    //     {
    //         title: 'Mobile Homes for Sale by Owner',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'mobile-homes-for-sale'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'mobile-homes-for-sale'}});
    //         },
    //     },
    //     {
    //         title: 'RVs for Sale',
    //         to: {name: 'search-classified-classifiedCategory', params: {classifiedCategory: 'rvs-for-sale'}},
    //         route: () => {
    //             this.$router.push({name: 'SearchClassified', params: {classifiedCategory: 'rvs-for-sale'}});
    //         },
    //     },
    // ];

    get AppVersion() {
        return AppVersion;
    }

    // public ipadIcon =
    //     this.$vuetify.breakpoint.width <= 1220 &&
    //     this.$vuetify.breakpoint.width >= 960;

    get ipadIconPb() {
        return this.$vuetify.breakpoint.width <= 1220 && this.$vuetify.breakpoint.width >= 960 ? 'pb-0' : 'pb-16';
    }
    get ipadIconPt() {
        return this.$vuetify.breakpoint.width <= 1220 && this.$vuetify.breakpoint.width >= 960 ? 'pt-0' : 'pt-16';
    }

    public ipadProFooter(number: number) {
        if (this.$vuetify.breakpoint.width <= 1220 || this.$vuetify.breakpoint.width >= 960) {
            return number;
        } else return 3;
    }

    scrollTop() {
        scrollToTop();
    }
    public addSubscriber() {
        this.LoaderSrv.showFullScreenLoader();
        new MailerLiteApi().AddSubscriber(new MailerLitePayLoadModel({Email: this.tempEmail, Fields: {source: 'Subscriber'}})).subscribe(res => {
            if (!!res.Data) {
                this.$refs.footerBaseform.reset();
                this.tempEmail = '';
                this.AlertSrv.show('success', res.Message ?? 'Successfuly Added Subscriber');
                this.LoaderSrv.hideFullScreenLoader();
            }
        });
    }
    get fieldFocusOff() {
        return this.tempEmail === '' || this.tempEmail === null ? null : 'required|email';
    }

    get routeNameForSubscribeCard() {
        if (this.$route.name === 'index') {
            return 'Home';
        } else {
            return this.$route.name
                ?.split('-')
                .map(x => x[0].toUpperCase() + x.slice(1))
                .join(' ');
        }
        // return this.$route.name?.replaceAll('-', ' ').toUpperCase()
    }

    public linkForSolutions(item: string) {
        if (item === 'Contacts') {
            return '/solutions/' + item.replace(' ', '-').toLowerCase() + '-crm-solution';
        }
        return '/solutions/' + item.replace(' ', '-').toLowerCase() + '-solution';
    }
}
