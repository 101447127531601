import {BlogModel, BlogStatusModel, BlogListingViewModel, PaginationModel, AttachmentReferenceViewModel} from '@/sdk';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BaseApi} from '../base.api';

export class BlogApi extends BaseApi {
    public GetPublishedBlogs(filter: FilterModel, category: string = '', solution: string = '') {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_RequestAsync<PaginationModel<BlogListingViewModel>>(
            `${this.ApiUrl}/Blog/GetPublishedBlogs?filter=${JSON.stringify(filter)}&category=${category}&solution=${solution}`
        );
    }

    public getRecentArticles(currentBlogId: string) {
        return this.GET_RequestAsync<PaginationModel<BlogListingViewModel>>(`${this.ApiUrl}/Blog/GetRecentArticles/${currentBlogId}`);
    }

    public getBySlug(slug: string) {
        return this.GET_RequestAsync<BlogModel>(`${this.ApiUrl}/Blog/GetBySlug/${slug}`);
    }

    public RecordView(blogId: string) {
        return this.GET_RequestAsync<void>(`${this.ApiUrl}/Blog/RecordView/${blogId}`);
    }
}
