import {PARK_SITE_STATUSES} from '@/globals';
import {ParkInformationViewModel} from '.';
import {AdViewModel, CityModel} from '..';

export class ParkSiteDataViewModel {
    public Id?: string | null = null;
    public AdId?: string | null = null;
    public Latitude: number | null = null;
    public Longitude: number | null = null;
    public Address: string | null = null;
    public City: string | null = null;
    public State: string | null = null;
    public County: string | null = null;
    public Country: string | null = null;
    public CountyGisWebSite: string | null = null;
    public CountyWebSite: string | null = null;
    public StateWebSite: string | null = null;
    public Zip: string | null = null;
    public LastUpdatedBy: string | null = null;
    public LastUpdatedDate: string | null = null;
    public ParkInformation: ParkInformationViewModel | null = null;
    public ParkNo: number | null = null;
    public ParkNoPredefined: number | null = null;

    public TotalMhLots: string | null = null;
    public TotalRVLots: string | null = null;
    public CityData: CityModel | null = null;
    public Contacted: boolean | null = null;
    public IsClaimedByMe: boolean | null = null;
    // public EntryCounter: number | null = null;
    public ClaimedStatus: boolean | null = null;
    public Status: string | null = null;
    public Active?: boolean | null = null;

    public PublishedParkSiteAd: AdViewModel | null = null;

    constructor(data?: Partial<ParkSiteDataViewModel>) {
        Object.assign(this, data);
    }

    public get pinType() {
        if (!this.Active) {
            return 'InActive';
        }
        return this.Status;
    }

    public get pinTypeForMyParks() {
        return this.ClaimedStatus ? 'Claimed' : 'UnClaimed';
    }

    public get pinTypeUser() {
        if (this.Contacted) {
            return 'Contacted';
        }
        return this.Status;
    }
}
