import {ServiceClass} from '@/decorators';
import {AddSupportTicketModel, ListSupportTicketModel, SupportTicketApi, UpdateSupportTicketModel} from '@/sdk';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class SupportTicketService {
    public SupportTickets = new BehaviorSubject<Array<ListSupportTicketModel>>([]);
    public SupportTicket = new BehaviorSubject(new UpdateSupportTicketModel());
    public Filter: FilterModel = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;
    public UnAssignedCount = new BehaviorSubject(0);

    public includeInActive: boolean = false;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
        this.getAll();
    };

    public getAll() {
        new LoaderService().showFullScreenLoader();
        new SupportTicketApi()
            .getAll(this.Filter, this.includeInActive)
            .subscribe(({Data}) => {
                if (Data) {
                    this.SupportTickets.next(
                        Data.Data.filter(ticket => {
                            if (this.Filter.Status == null) {
                                return ticket.Status == 'OPEN' || ticket.Status == 'UnAssigned';
                            } else {
                                return ticket;
                            }
                        }) ?? []
                    );

                    this.TotalRows = Data?.TotalRows || 0;
                    this.UnAssignedCount.next(Data.UnAssignedCount);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();
        new SupportTicketApi()
            .getById(id)
            .subscribe(({Data}) => {
                if (Data) {
                    this.SupportTicket.next(Data);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
