export class AddSupportTicketModel {
    public LookupId: string | null = null;
    public FirstName: string | null = null;
    public LastName: string | null = null;
    public IssueDate: string | null = null;
    public PhoneNo: string | null = null;
    public TimetoReach: string | null = null;
    public Description: string | null = null;
    public Details: string | null = null;

    constructor(data?: Partial<AddSupportTicketModel>) {
        Object.assign(this, data);
    }
}

export class ListSupportTicketModel {
    public Id: string | null = null;
    public Status: string | null = null;
    public CloseDate: string | null = null;
    public CreatedDate: string | null = null;
    public CreatedBy: string | null = null;
    public AssignedTo: string | null = null;
    public Category: string | null = null;
    public LastUpdated: string | null = null;
    public LastEditedBy: string | null = null;
    public ActiveStatus: string | null = null;
    public TicketNumber: number | null = null;

    constructor(data?: Partial<ListSupportTicketModel>) {
        Object.assign(this, data);
    }
}

export class UpdateSupportTicketModel {
    public Id: string | null = null;
    public Lookup: string | null = null;
    public AssignedTo: string | null = null;
    public CreatedBy: string | null = null;
    public CreatedDate: string | null = null;
    public AssignedToId: string | null = null;
    public IssueDate: string | null = null;
    public CloseDate: string | null = null;
    public Status: string | null = null;
    public PhoneNumber: string | null = null;
    public TimeToReach: string | null = null;
    public Description: string | null = null;
    public SupportTitcketComments: Array<TicketCommentModel> | null = null;
    public TicketNumber: number | null = null;

    constructor(data?: Partial<UpdateSupportTicketModel>) {
        Object.assign(this, data);
    }
}

export class TicketCommentModel {
    public Id: string | null = null;
    public Descriptions: string | null = null;
    public Name: string | null = null;

    constructor(data?: Partial<TicketCommentModel>) {
        Object.assign(this, data);
    }
}
export class UpdateSupportTicketStatusModel {
    public Id: string | null = null;
    public Status: string | null = null;

    constructor(data?: Partial<UpdateSupportTicketStatusModel>) {
        Object.assign(this, data);
    }
}
