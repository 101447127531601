import {FilterModel} from '@/sdk/models/common/filter.model';
import {GetParkSiteImagesModel, ParkSiteImagesModel, ParkSiteViewModel, SaveParkViewModel} from '@/sdk/models/park-site';
import {UserParkSiteInfoModel} from '@/sdk/models/park-site/user-park-site-info.model';
import {BaseApi} from '../base.api';

export class UserParkSiteApi extends BaseApi {
    public GetParkByIdUser(ParkId: string) {
        return this.GET_Request<SaveParkViewModel>(`${this.ApiUrl}/ParkSite/GetParkById/${ParkId}`);
    }

    public GetParkSiteInfo(ParkId: string) {
        return this.GET_Request<UserParkSiteInfoModel>(`${this.ApiUrl}/UserParkListing/GetParkSiteInfo/${ParkId}`);
    }
    public MarkAsContacted(ParkId: string, status: boolean) {
        return this.GET_Request<any>(`${this.ApiUrl}/UserParkSite/MarkContacted/${ParkId}?status=${status}`);
    }

    public MarkAllUnContacted(userId: string) {
        return this.GET_Request<any>(`${this.ApiUrl}/UserParkSite/MarkAllUnContacted/${userId}`);
    }

    // public GetAllParksUser(swlat: number, swlng: number, nelat: number, nelng: number, status: string | null = 'TRUE') {
    //     return this.GET_Request<Array<ParkSiteViewModel>>(
    //         `${this.ApiUrl}/UserParkSite/GetAllParks?swlat=${swlat}&swlng=${swlng}&nelat=${nelat}&nelng=${nelng}&Status=${status}`
    //     );
    // }
    public GetAllMyParks(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<Array<ParkSiteViewModel>>(`${this.ApiUrl}/UserParkListing/GetAllListing?filter=${filter}`);
    }
}
