import {ServiceClass} from '@/decorators';
import {CRMApi, CRMListModel, CRMModel} from '@/sdk';
import {ParkSiteApi} from '@/sdk/api-services/park-site/park-site.api';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {DynamicPagesInfoModel} from '@/sdk/models/park-site';
import {BehaviorSubject, Subject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class DynamicPagesService {
    public dynamicPageData: DynamicPagesInfoModel = new DynamicPagesInfoModel();

    public getData(parkId: string) {
        this.dynamicPageData = new DynamicPagesInfoModel();

        return new Promise((resolve, reject) => {
            new LoaderService().showFullScreenLoader();

            new ParkSiteApi()
                .DynamicPagesInfo(parkId)
                .subscribe({
                    next: ({Data}) => {
                        if (Data) {
                            this.dynamicPageData = Data;
                        }
                        resolve(null);
                    },
                    error: err => {
                        reject(err);
                    },
                    complete: () => {
                        new LoaderService().hideFullScreenLoader();
                    },
                })
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }
}
