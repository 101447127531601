import {Component} from 'nuxt-property-decorator';
import {ValidationObserver} from 'vee-validate';
import VueWrapper from '~/classes/vue.wrapper';

@Component({
    components: {
        ValidationObserver,
    },
})
export default class BaseFormComponent extends VueWrapper {
    // Refs
    public $refs!: {
        baseForm: any;
    };

    // Methods
    public reset() {
        this.$refs.baseForm.reset();
    }

    public isInvalid() {
        return this.$refs.baseForm && this.$refs.baseForm['flags'];
    }

    public validate() {
        return (this.$refs.baseForm as any)?.validate() || true;
    }
}
