export class ParkContactModel {
    public Id: string | null = null;
    public ParkId: string | null = null;
    public FirstName: string | null = null;
    public LastName: string | null = null;
    public Email: string | null = null;
    public Company: string | null = null;
    public Type: string | null = null;
    public Phone: string | null = null;
    public AltPhone: string | null = null;
    public ContactDetails: string | null = null;
    constructor(data?: Partial<ParkContactModel>) {
        Object.assign(this, data);
    }
}