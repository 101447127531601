import {ServiceClass} from '@/decorators';
import {LISTING_STATUS} from '@/globals';
import {ParkListingApi} from '@/sdk';
import {FilterModel, ListingFilterViewModel} from '@/sdk/models/common/filter.model';
import {GetAllParkListingModel, ListingDataModel, ParkLisitingUserViewsModel, ParkLisitingViewModel} from '@/sdk/models/park-listing';
import {Subject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {AlertService, PaginationService} from '..';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class ParkListingService {
    public ParkListing: Array<GetAllParkListingModel> = [];
    public SoldParkAds: Array<ParkLisitingViewModel> = [];
    public SingleParkListing = new ListingDataModel();
    public parkListingApi = new ParkListingApi();
    public publicSiteParkListings: Array<ParkLisitingViewModel> = [];
    public listingFilterState = '';
    public parkListingViews: Array<ParkLisitingUserViewsModel> = [];
    public listingFilter: ListingFilterViewModel = new ListingFilterViewModel();
    public Filter = new FilterModel();
    public ListingViewsFilter = new FilterModel({
        SortDesc: true,
    });
    TotalRows: number = 0;
    ParkViewsTotalRows: number = 0;
    public listingStatus: any = {LISTING: 'Listing', PRICEREDUCED: 'Price Reduced', SALEPENDING: 'Sale Pending', CLOSED: 'Closed', SOLD: 'Sold'};
    public options: DataOptions = {} as DataOptions;

    public SelectedAd: GetAllParkListingModel | null = null;

    public HasAnyListing: boolean = false;


    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    // public reset() {
    //     this.User$.next(new UsersModel());
    // }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;

        // this.GetAllParkListing();
    };
    ListingViewsFilterData = (event: DataOptions) => {
        this.ListingViewsFilter.PageNo = event.page;
        this.ListingViewsFilter.PageSize = event.itemsPerPage;
        this.ListingViewsFilter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        if (this.ListingViewsFilter.SortBy) {
            this.ListingViewsFilter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
        } else {
            this.ListingViewsFilter.SortDesc = true;
        }
    };

    public GetAllParkListing() {
        return new Promise((resolve, reject) => {
            new LoaderService().showFullScreenLoader();
            this.parkListingApi
                .GetAllParkListing(this.Filter)
                .subscribe(
                    ({Data}) => {
                        this.ParkListing = Data?.Data!;
                        this.ParkListing = this.ParkListing.map(x => {
                            x.ListingStatus = this.listingStatus[x.ListingStatus!.toString()];
                            return x;
                        });
                        this.TotalRows = Data?.TotalRows || 0;

                        this.HasAnyListing = (Data as any).HasAnyListing;

                        resolve(Data);
                    },
                    error => {
                        reject(error);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }
    public GetSoldParksListing(filter: FilterModel, listingFilter: ListingFilterViewModel) {
        return new Promise((resolve, reject) => {
            // new LoaderService().showFullScreenLoader();
            // this.parkListingApi
            //     .GetSoldParks(filter)
            //     .subscribe(
            //         ({Data}) => {
            //             new LoaderService().hideFullScreenLoader();
            //             this.SoldParkAds = Data?.Data!;
            //             new PaginationService().TotalPages = Data?.TotalRows!;
            //             resolve(Data);
            //         },
            //         error => {
            //             reject(error);
            //         }
            //     )
            //     .add(() => {
            //         new LoaderService().hideFullScreenLoader();
            //     });
            new LoaderService().showFullScreenLoader();
            this.parkListingApi
                .GetSoldParks(filter, listingFilter)
                .subscribe(
                    res => {
                        this.SoldParkAds = res.Data?.Data ?? [];
                        // new PaginationService().TotalPages = res.Data?.TotalRows!;
                        // this.listingFilterState = this.listingFilter.State!;

                        resolve(res.Data);
                    },
                    err => {
                        new AlertService().show('error', err.Message || err.message);
                        reject(err);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }
    public GetParkListingViews(listingViewsFilter: FilterModel) {
        new LoaderService().showFullScreenLoader();
        this.parkListingApi
            .GetParkListingViews(listingViewsFilter)
            .subscribe(
                ({Data}) => {
                    this.parkListingViews = Data?.Data ?? [];
                    this.ParkViewsTotalRows = Data?.TotalRows ?? 0;
                },
                error => {}
            )
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    // public getById(id: string) {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             new LoaderService().showFullScreenLoader();

    //             this.parkListingApi
    //                 .GetParkListingById(id)
    //                 .subscribe(({Data}) => {
    //                     if (Data) {
    //                         this.SingleParkListing = Data;
    //                         resolve(this.SingleParkListing);
    //                     }
    //                 })
    //                 .add(() => {
    //                     new LoaderService().hideFullScreenLoader();
    //                 });
    //         } catch (e) {
    //             reject(e);
    //         }
    //     });
    // }

    public PublicSiteListing(filter: FilterModel, listingFilter?: ListingFilterViewModel) {
        new LoaderService().showFullScreenLoader();
        this.parkListingApi
            .PublicSiteListing(filter, listingFilter ?? this.listingFilter)
            .subscribe(
                res => {
                    this.publicSiteParkListings = res.Data?.Data ?? [];
                    new PaginationService().TotalPages = res.Data?.TotalRows!;
                    this.listingFilterState = this.listingFilter.State!;
                },
                err => {
                    new AlertService().show('error', err.Message || err.message);
                }
            )
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
