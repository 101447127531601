import {ServiceClass} from '@/decorators';
import {LoginModel, SessionModel, SignupModel, UserMembershipViewModel} from '@/sdk/models';
import {ResetPasswordModel} from '@/sdk/models/authentication/reset-password.model';
import {BaseApi} from '../base.api';

@ServiceClass()
export class AccountsApi extends BaseApi {
    public Login(loginData: LoginModel) {
        return this.POST_Request<SessionModel>(`${this.ApiUrl}/Account/login`, loginData);
    }

    public RegenerateToken() {
        return this.GET_Request<{Token: string}>(`${this.ApiUrl}/Account/RegenerateToken`);
    }

    public RemoteLogin() {
        return this.GET_Request<string>(`${this.ApiUrl}/Account/RemoteLogin`);
    }
    public AcceptDisclaimer() {
        return this.GET_Request<string>(`${this.ApiUrl}/Account/AcceptDisclaimer`);
    }

    public Logout() {
        return this.GET_Request<void>(`${this.ApiUrl}/Account/Logout`);
    }

    public signup(signUpData: SignupModel) {
        return this.POST_Request<SessionModel>(`${this.ApiUrl}/Account/Signup`, signUpData);
    }
    public sendLink(email: string) {
        return this.POST_Request(`${this.ApiUrl}/Account/SendLink`, {email});
    }

    public ResetPassword(resetPass: ResetPasswordModel) {
        return this.POST_Request<ResetPasswordModel>(`${this.ApiUrl}/Account/ResetPassword`, resetPass);
    }

    public ReactivateMembership(userId: string) {
        return this.GET_Request<UserMembershipViewModel>(`${this.ApiUrl}/Account/ReactivateMembership/${userId}`);
    }

    public SendReactivationRequest(email: string) {
        return this.GET_Request(`${this.ApiUrl}/Account/SendReactivationRequest?email=${email}`);
    }
}
