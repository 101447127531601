import {CompanyModel} from '@/sdk/models';
import {BaseApi} from '../base.api';

export class CompanyApi extends BaseApi {
    public getById(id: string) {
        return this.GET_Request<CompanyModel>(`${this.ApiUrl}/Company/${id}`);
    }

    public update(companyData: CompanyModel) {
        return this.PUT_Request(`${this.ApiUrl}/Company`, companyData);
    }

    public UpdateProfilePicture(id: string, file: File) {
        const data = new FormData();
        data.append('Id', id);
        data.append('Image', file);

        return this.PUT_Request(`${this.ApiUrl}/Company/UpdateLogo`, data);
    }
}
