import {MyParksSiteModel, PaginationModel, StatusModel} from '@/sdk';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BaseApi} from '../base.api';

export class MyParkSiteApi extends BaseApi {
    public GetAllMyParks(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<MyParksSiteModel>>(`${this.ApiUrl}/MyParkSite?filter=${JSON.stringify(filter)}`);
    }

    public changeStatus(status: StatusModel) {
        return this.PUT_Request(`${this.ApiUrl}/MyParkSite`, status);
    }
    public RenewClaim(id: string) {
        return this.GET_Request(`${this.ApiUrl}/myparksite/RenewClaim/${id}`);
    }
}
