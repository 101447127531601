import {ChangePasswordModel, PaginationModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {ChangeUserPasswordModel, StatusModel, UsersModel, RoleUsersModel} from '@/sdk/models/users/users.model';
import {BaseApi} from '../base.api';

export class UsersApi extends BaseApi {
    public getAll(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<UsersModel>>(`${this.ApiUrl}/Account/GetAll?filter=${JSON.stringify(filter)}`);
    }

    public getById(id: string) {
        return this.GET_Request<UsersModel>(`${this.ApiUrl}/Account/GetUser/${id}`);
    }
    public getByRole(role: string) {
        return this.GET_Request<RoleUsersModel[]>(`${this.ApiUrl}/Account/RoleUsers/${role}`);
    }

    public create(userData: UsersModel) {
        return this.POST_Request(`${this.ApiUrl}/Account`, userData);
    }

    public update(userData: UsersModel) {
        return this.PUT_Request(`${this.ApiUrl}/Account/Update`, userData);
    }

    public deleteById(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/Account/Delete/${id}`);
    }

    public changeStatus(status: StatusModel) {
        return this.POST_Request(`${this.ApiUrl}/Account/ChangeStatus`, status);
    }

    public changePassword(changePassword: ChangeUserPasswordModel) {
        return this.POST_Request(`${this.ApiUrl}/Account/ChangePassword`, changePassword);
    }

    public confirmEmail(id: string) {
        return this.GET_Request(`${this.ApiUrl}/Account/EmailConfirmation/${id}`);
    }

    public AddToRole(id: string, Role: string) {
        return this.GET_Request(`${this.ApiUrl}/Account/AddToRole/${id}?role=${Role}`);
    }

    public updateProfilePicture(id: string, file: File) {
        const data = new FormData();
        data.append('Id', id);
        data.append('Image', file);
        return this.PUT_Request(`${this.ApiUrl}/Account/profilepicture`, data);
    }

    // public ActivateAccount(email: string) {
    //     return this.GET_Request(`${this.ApiUrl}/Account/ActivateAccount?email=${email}`);
    // }
}
