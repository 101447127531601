import {ServiceClass} from '@/decorators';
import {MyParkSiteApi, MyParksSiteModel} from '@/sdk';
import {UsersApi} from '@/sdk/api-services/users/users.api';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {UsersModel, RoleUsersModel} from '@/sdk/models/users/users.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class MyParksSiteService {
    public MyParks: Array<MyParksSiteModel> = [];
    // public RoleUsers = new BehaviorSubject<Array<RoleUsersModel>>([]);
    // public User$ = new BehaviorSubject(new UsersModel());
    public Filter = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    public TotalClaimedParks: number | null = null;
    public TotalHits: number = 0;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    // public reset() {
    //     this.User$.next(new UsersModel());
    // }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;

        this.getAllMyParks();
    };

    public getAllMyParks() {
        return new Promise((resolve, reject) => {
            new LoaderService().showFullScreenLoader();
            new MyParkSiteApi()
                .GetAllMyParks(this.Filter)
                .subscribe(
                    res => {
                        this.TotalHits++;
                        const response = res as any;
                        new LoaderService().hideFullScreenLoader();
                        this.MyParks = res.Data?.Data!;
                        // console.log(this.MyParks);
                        this.TotalRows = res.Data?.TotalRows || 0;
                        this.TotalClaimedParks = response.Data.TotalClaimedParks;
                        // console.log(this.TotalClaimedParks);

                        resolve(res.Data?.Data);
                    },
                    err => {
                        reject(err);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    // public getById(id: string) {
    //     new LoaderService().showFullScreenLoader();

    //     new UsersApi()
    //         .getById(id)
    //         .subscribe(({Data}) => {
    //             if (Data) {
    //                 this.User$.next(Data);
    //             }
    //         })
    //         .add(() => {
    //             new LoaderService().hideFullScreenLoader();
    //         });
    // }
}
