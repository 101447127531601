import {ServiceClass} from '@/decorators';
import {ApiResponseModel, ParksInAreaSpViewModel} from '@/sdk';
import {ParkSiteApi} from '@/sdk/api-services/park-site/park-site.api';
import {
    GetParkSiteImagesModel,
    ParkClaimHolderModel,
    ParkHistoryDataModel,
    ParkSiteDataViewModel,
    ParkSiteFieldsViewModel,
    ParkSiteImagesModel,
    ParkSiteViewModel,
    SaveParkViewModel,
} from '@/sdk/models/park-site';
import {BehaviorSubject} from 'rxjs';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class ParkSiteService {
    public parkSiteApi: ParkSiteApi = new ParkSiteApi();

    public parksList$: BehaviorSubject<Array<ParksInAreaSpViewModel>> = new BehaviorSubject<Array<ParksInAreaSpViewModel>>([]);

    public parkImagesList: Array<{id?: string | null; file?: File; sequence: number; url: string; uniqueId: string}> = [];

    public parkToEdit: SaveParkViewModel = new SaveParkViewModel();

    public parkSiteDetails: SaveParkViewModel = new SaveParkViewModel();

    public parkSiteImages = new ParkSiteImagesModel();

    public parkClaimHolder = new ParkClaimHolderModel();

    public getParkSiteImages: Array<GetParkSiteImagesModel> = [];

    public parkHistoryData: Array<ParkHistoryDataModel> = [];

    public viewableParkData: ParkSiteDataViewModel = new ParkSiteDataViewModel();

    public parkSiteId: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public ParkId: string | null = null;

    public TotalParkSites: number | null = null;

    public slugsList: Array<string> = [];

    constructor() {}

    public GetParksInArea(swlat: number, swlng: number, nelat: number, nelng: number, status: string | null) {
        return new Promise((resolve: (a: ApiResponseModel<Array<ParksInAreaSpViewModel>>) => void, reject) => {
            this.parkSiteApi.GetParksInArea(swlat, swlng, nelat, nelng, status).subscribe(
                async res => {
                    if (res.Data) {
                        for (let u = 0; u < res.Data.length; u++) {
                            res.Data[u] = new ParksInAreaSpViewModel(res.Data[u]);
                        }
                    }
                    this.parksList$.next(res.Data ?? []);
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public GetParkById(ParkId: string) {
        return new Promise((resolve: (data: SaveParkViewModel) => void, reject) => {
            this.parkSiteApi.GetParkById(ParkId).subscribe(
                res => {
                    if (res.Data) {
                        this.parkToEdit = new SaveParkViewModel(res.Data);
                        if (this.parkToEdit.ParkFieldsData?.length) {
                            // this.parkToEdit.ParkFieldsDataObj = new ParkSiteFieldsViewModel();

                            this.parkToEdit.ParkFieldsDataObj = new ParkSiteFieldsViewModel(
                                this.parkToEdit.ParkFieldsData.map(x => {
                                    if (x.Value != null) {
                                        return {[`${x.Key}`]: x.Value};
                                    }
                                    return {};
                                }).reduce((r, c) => Object.assign(r, c))
                            );
                        }
                    }
                    resolve(this.parkToEdit);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public GetParkClaimHolder(ParkId: string) {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            this.parkSiteApi
                .GetParkHolderDetails(ParkId)
                .subscribe(
                    res => {
                        if (!!res) {
                            this.parkClaimHolder = res.Data!;
                        }
                    },
                    err => {
                        //
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                    resolve(null);
                });
        });
    }
    public GetParkHistoryData(ParkId: string) {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            this.parkSiteApi
                .GetParkActivityHistory(ParkId)
                .subscribe(res => {
                    if (!!res) {
                        this.parkHistoryData = res.Data!;
                    }
                })
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                    resolve(null);
                });
        });
    }

    public GetParkDetails(ParkId: string) {
        return new Promise((resolve: (data: SaveParkViewModel) => void, reject) => {
            this.parkSiteApi.GetParkDetails(ParkId).subscribe(
                res => {
                    if (res.Data) {
                        this.parkSiteDetails = res.Data;
                        if (this.parkSiteDetails.ParkFieldsData?.length) {
                            this.parkSiteDetails.ParkFieldsData = this.parkSiteDetails.ParkFieldsData.map(x => {
                                if (x.FieldType === 'Dropdown') {
                                    x.Value = x.Items?.find(key => {
                                        return x.Value === key.Key;
                                    })?.Value as any;
                                }
                                return x;
                            });
                            this.parkSiteDetails.ParkFieldsDataObj = new ParkSiteFieldsViewModel();

                            this.parkSiteDetails.ParkFieldsDataObj = new ParkSiteFieldsViewModel(
                                this.parkSiteDetails.ParkFieldsData.map(x => {
                                    return {[`${x.Key}`]: x.Value};
                                }).reduce((r, c) => Object.assign(r, c))
                            );
                        }
                    }
                    resolve(this.parkSiteDetails);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public GetParkData(ParkId: string) {
        return new Promise((resolve: (data: ParkSiteDataViewModel) => void, reject) => {
            this.parkSiteApi.GetParkData(ParkId).subscribe(
                res => {
                    if (res.Data) {
                        this.viewableParkData = res.Data;
                    }
                    resolve(this.viewableParkData);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public getField(name: string) {
        return this.parkToEdit.ParkFieldsData?.find(x => x.Key === name);
    }

    public getAllSlugs() {
        return new Promise((resolve: (data: Array<string>) => void) => {
            if (this.slugsList.length > 0) {
                resolve(this.slugsList);
            } else {
                this.parkSiteApi.GetAllSlugs().subscribe(
                    res => {
                        this.slugsList = res.Data ?? [];
                        resolve(this.slugsList);
                    },
                    err => {
                        resolve(this.slugsList);
                    }
                );
            }
        });
    }

    public GetParkImages(id: string) {
        return new Promise((resolve, reject) => {
            this.parkSiteApi.GetParkImages(id).subscribe(
                res => {
                    if (res.Data) {
                        this.getParkSiteImages = res.Data!;

                        this.parkImagesList =
                            this.getParkSiteImages?.map(x => {
                                return {
                                    id: x.Id,
                                    sequence: x.Sequence ? +x.Sequence : 0,
                                    url: x.Url!,
                                    uniqueId: x.Id!,
                                };
                            }) ?? [];
                    }
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public GetParkSiteCount() {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            new ParkSiteApi()
                .GetParkSiteCount()
                .subscribe(Res => {
                    this.TotalParkSites = Res.Data;
                })
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                    resolve(null);
                });
        });
    }
}
