import {ServiceClass} from '@/decorators';
import {KnowledgeBaseApi} from '@/sdk/api-services/knowledge-base/knowledge-base.api';
import {KnowledgeBaseModel, ListKnowledgebaseViewModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class KnowledgeBaseService {
    public AllKnowledgeBase = new BehaviorSubject<Array<ListKnowledgebaseViewModel>>([]);
    public AllKnowledgeBaseGettingStarted = new BehaviorSubject<Array<ListKnowledgebaseViewModel>>([]);
    public KnowledgeBase = new BehaviorSubject(new KnowledgeBaseModel());
    public Filter: FilterModel = new FilterModel({
        EntityStatus: 'PUBLISH'
    });

    public TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
        // this.Filter.EntityStatus = 'PUBLISH';
        console.log(this);
    }

    FilterData(event: DataOptions) {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
        this.getAll();
    }

    public getAll(categoryId?: string) {
        new LoaderService().showFullScreenLoader();
        new KnowledgeBaseApi()
            .getAll(this.Filter, categoryId)
            .subscribe(({Data}) => {
                if (Data) {
                    this.AllKnowledgeBase.next(Data.Data ?? []);
                    this.TotalRows = Data?.TotalRows || 0;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
    public getAllGettingStarted(categoryId?: string, tabName?: string) {
        new LoaderService().showFullScreenLoader();
        new KnowledgeBaseApi()
            .getAll(this.Filter, tabName, categoryId)
            .subscribe(({Data}) => {
                if (Data) {
                    this.AllKnowledgeBaseGettingStarted.next(Data.Data ?? []);
                    this.TotalRows = Data?.TotalRows || 0;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();

        new KnowledgeBaseApi()
            .getById(id)
            .subscribe(({Data}) => {
                if (Data) {
                    this.KnowledgeBase.next(Data);
                    this.KnowledgeBase.value.PublishedDate = Data.PublishedDate?.slice(0, 10)!;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
