export class ParkSiteFieldsViewModel {
    public SecurityCameras: string | null = null;
    public CommunitySwimminngPool: string | null = null;
    public AgeRestricted: string | null = null;
    public CertifiedStormShelter: string | null = null;
    public CommunityActivityBuilding: string | null = null;
    public NoAgeRestricted: string | null = null;
    public Laundary: string | null = null;
    public OnSiteOffice: string | null = null;
    public StorageUnitRentals: string | null = null;
    public RuralLiving: string | null = null;
    public RVSepticDumpStation: string | null = null;
    public UrbanLiving: string | null = null;
    public RVCampHost: string | null = null;
    public OnSiteManagement: string | null = null;
    public BasketballTennisCourts: string | null = null;
    public NoMobileHomeLots: string | null = 'False';
    public TotalMHLots: number | null = 0;
    public TOHsMHLots: number | null = 0;
    public POHsMHLots: number | null = 0;
    public DevelopedMHLots: number | null = 0;
    public UnDevelopedMHLots: number | null = 0;
    public NoRVLots: string | null = 'False';
    public TotalRVLots: number | null = 0;
    public DevelopedRVLots: number | null = 0;
    public UnDevelopedRVLots: number | null = 0;
    public RVLotSurfaceTypeGravel: string | null = null;
    public RVLotSurfaceTypeConcrete: string | null = null;
    public RVLotSurfaceTypeAsphalt: string | null = null;
    public RVLotSurfaceTypeGrass: string | null = null;
    public ParkUtilityWaterSource: string | null = null;
    public ParkUtilitySewerType: string | null = null;
    public ParkUtilityHeatingFuel: string | null = null;
    public ParkUtilityTrashService: string | null = null;
    public StreetAndRoadsStreetType: string | null = null;
    public StreetAndRoadsOwnedBy: string | null = null;
    public IncludeSingleFamilyResidence: string | null = null;
    public TotalSFRUnits: number | null = 0;
    public IncludeStorageUnits: string | null = null;
    public TotalStorageUnits: number | null = 0;
    public FloodPlain: string | null = null;

    constructor(data?: Partial<ParkSiteFieldsViewModel>) {
        Object.assign(this, data);
    }
}
