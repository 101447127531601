export class ChangePasswordClientModel {
    public Id: string | null = null;
    public Token: string | null = null;
    public Password: string | null = null;
    public ConfirmPassword: string | null = null;

    constructor(data?: Partial<ChangePasswordClientModel>) {
        Object.assign(this, data);
    }
}
