import {SessionModel, UserSession} from '@/sdk';
import {Component} from 'nuxt-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import {CoreService} from '~/sdk/services/core.service';

@Component
export default class ClientDrawerComponent extends VueWrapper {
    public CoreSrv = new CoreService();
    public Session = new SessionModel();

    public items1 = [
        {
            title: 'Evolve with MHParks',
            link: 'How MH Park Works',
            icon: 'mdi-lightbulb-outline',
        },
        {
            title: 'Take the MHParks Tour',
            link: 'Product Tour',
            icon: 'mdi-cube-outline',
        },

        {
            title: 'Frequently Asked Questions',
            link: 'FAQs',
            icon: 'mdi-frequently-asked-questions',
        },
        {
            title: 'Learn More About MHParks',
            link: 'Learn More',
            icon: 'mdi-help-circle-outline',
        },
        {
            title: 'See How MHParks Rates',
            link: 'Review',
            icon: 'mdi-star-outline',
        },
    ];
    public Links = [
        {title: 'Home', name: 'index'},
        {title: 'Solutions', name: 'solutions'},
        {title: 'Pricing', name: 'pricing'},
        // {title: 'Blog', name: 'blog'},
        // {title: 'Contact', name: 'contact-us'},
    ];
    
    public adsMenu = [
        {title: 'PARKS FOR SALE ADS', link: 'park-for-sale-ads'},
        {title: 'CLASSIFIED ADS', link: 'classified-ads'},
        {title: 'PROFESSIONALS', link: 'professionals'},
    ]
    public resources: Array<{title: string; link: string}> = [
        {title: 'BLOG', link: 'blog'},
        {title: 'HELP CENTER', link: 'https://help.mhparks.com'},
        {title: 'PROMOTIONS', link: 'https://promo.mhparks.com'},
        {title: 'CONTACT US', link: 'contact-us'},
        {title: 'OUR TEAM', link: 'our-team'},
    ];
    
    public additionalTools = [
        {
            title: 'Park Sites',
            link: 'park-sites-solution',
            icon: 'mdi-tree-outline',
        },
        {
            title: 'Advertise',
            link: 'advertising',
            icon: 'mdi-lightbulb-outline',
        },
        {
            title: 'Sold Parks',
            link: 'sold-parks-solution',
            icon: 'mdi-cube-outline',
        },

        {
            title: 'My Parks',
            link: 'my-parks-solution',
            icon: 'mdi-frequently-asked-questions',
        },
        {
            title: 'Contacts',
            link: 'mobile-home-park-industry-contacts',
            icon: 'mdi-help-circle-outline',
        },
    ];

    public toolsGroup: boolean = false;

    public get drawerWidth() {
        if (this.$vuetify.breakpoint.width < 600 && process.client) {
            return window.innerWidth;
        } else {
            return 350;
        }
    }

    public mounted() {}

    public router() {
        if (this.$route.name === 'index') {
            return false;
        } else return this.$router.push({name: 'index'});
    }

    public created() {
        this.AddSubscription$ = new UserSession()._session.subscribe(session => {
            this.Session = session ?? new SessionModel();
        });
    }
}
