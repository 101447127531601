import { CityModel, PaginationModel } from '@/sdk/models';
import { BaseApi } from '../base.api';

export class CityApi extends BaseApi {
    public getAll() {
        return this.GET_Request<PaginationModel<CityModel>>(
            `${this.ApiUrl}/City?filter=${JSON.stringify(
                this.FilterSrv.Filter
            )}`
        );
    }

    public addCityData(data: FormData) {
        return this.fileRequest('POST', `${this.ApiUrl}/City`, data);
    }
}
