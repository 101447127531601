export class ParkClaimHolderModel {
    public ParkName: string | null = null;
    public ClaimHolderName: string | null = null;
    public UserAddress: string | null = null;
    public UserCity: string | null = null;
    public UserState: string | null = null;
    public UserZip: string | null = null;
    public UserPhone: string | null = null;
    public CompanyName: string | null = null;
    public UserOfcsPhone: string | null = null;

    constructor(data?: Partial<ParkClaimHolderModel>) {
        Object.assign(this, data);
    }
}
