import {AnyObject} from '@/globals';
import {ListingViewsViewModel, PaginationModel} from '@/sdk';
import {ContactParkforSaleSellerModel, ContactSellerModel, PublishAdViewModel} from '@/sdk/models';
import {FilterModel, ListingFilterViewModel} from '@/sdk/models/common/filter.model';
import {ShareAdModel} from '@/sdk/models/common/share-ad.model';
import {
    AddParkListingModel,
    ChangeListingStatusModel,
    GetAllParkListingModel,
    GetParkListingImagesModel,
    ListingDataModel,
    ParkLisitingUserViewsModel,
    ParkLisitingViewModel,
    ParkListingImagesModel,
    SoldParkInformationModel,
} from '@/sdk/models/park-listing';
import {ParkSiteImagesModel} from '@/sdk/models/park-site';
import {BaseApi} from '../base.api';

export class ParkListingApi extends BaseApi {
    public GetAllParkListing(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<GetAllParkListingModel>>(`${this.ApiUrl}/UserParkListing/GetAllListing?filter=${JSON.stringify(filter)}`);
    }
    public GetSoldParks(filter?: FilterModel, filter1?: ListingFilterViewModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        if (!filter1) {
            filter1 = new ListingFilterViewModel();
        }

        return this.GET_Request<PaginationModel<ParkLisitingViewModel>>(
            `${this.ApiUrl}/UserParkListing/SoldParkAds?filter=${JSON.stringify(filter)}&listingfilter=${JSON.stringify(filter1)}`
        );
    }

    public PublicSiteListing(filter?: FilterModel, filter1?: ListingFilterViewModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        if (!filter1) {
            filter1 = new ListingFilterViewModel();
        }

        return this.GET_Request<PaginationModel<ParkLisitingViewModel>>(
            `${this.ApiUrl}/UserParkListing/PublicSiteListing?filter=${JSON.stringify(filter)}&listingfilter=${JSON.stringify(filter1)}`
        );
    }
    public SendToMailToParkForSaleAdOwner(contactSeller: ContactParkforSaleSellerModel) {
        return this.POST_Request(`${this.ApiUrl}/UserAds/SendMailToParkForSaleAdOwner`, contactSeller);
    }

    public GetParkListingById(ParkId: string) {
        return this.GET_Request<AddParkListingModel>(`${this.ApiUrl}/UserParkListing/GetListingById/${ParkId}`);
    }
    public GetParkListingViews(filter?: FilterModel) {
        return this.GET_Request<PaginationModel<ParkLisitingUserViewsModel>>(`${this.ApiUrl}/UserParkListing/GetListingViews?filter=${JSON.stringify(filter)}`);
    }
    public GetParkListingImages(ParkId: string) {
        return this.GET_Request<GetParkListingImagesModel>(`${this.ApiUrl}/UserParkListing/GetListingImages/${ParkId}`);
    }

    public GetPublicSiteListingById(listingId: string) {
        return this.GET_Request<ListingDataModel>(`${this.ApiUrl}/UserParkListing/GetPublicSiteListing/${listingId}`);
    }
    public ChangeListingStatus(listingId: string, status: string) {
        return this.GET_Request<AnyObject>(`${this.ApiUrl}/UserParkListing/ChangeListingStatus/${listingId}?status=${status}`);
    }

    public GetFeautedParkListings(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }

        return this.GET_Request<PaginationModel<ParkLisitingViewModel>>(
            `${this.ApiUrl}/UserParkListing/GetFeautedParkListings?filter=${JSON.stringify(filter)}`
        );
    }

    public ChangeListingAdStatus(listingId: string, status: string) {
        return this.GET_Request<AnyObject>(`${this.ApiUrl}/UserParkListing/ChangeListingAdStatus/${listingId}?status=${status}`);
    }

    public create(parkListingData: AddParkListingModel) {
        return this.POST_Request<AnyObject>(`${this.ApiUrl}/UserParkListing`, parkListingData);
    }
    public share(shareData: ShareAdModel) {
        return this.POST_Request<ShareAdModel>(`${this.ApiUrl}/UserParkListing/ShareParkForSaleAd`, shareData);
    }

    public update(parkListingData: AddParkListingModel) {
        return this.PUT_Request<AnyObject>(`${this.ApiUrl}/UserParkListing`, parkListingData);
    }

    public Delete(listingId: string) {
        return this.DELETE_Request(`${this.ApiUrl}/UserParkListing/${listingId}`);
    }

    public SaveSoldParkInformation(parkData: SoldParkInformationModel) {
        return this.POST_Request<SoldParkInformationModel>(`${this.ApiUrl}/UserParkListing/AddSoldParkDetail`, parkData);
    }

    public GetSoldParkInfo(listingId: string) {
        return this.GET_Request<SoldParkInformationModel>(`${this.ApiUrl}/UserParkListing/GetSoldParkInfo/${listingId}`);
    }

    public UndoSoldStatus(listingId: string) {
        return this.GET_Request<AnyObject>(`${this.ApiUrl}/UserParkListing/UndoSoldStatus/${listingId}`);
    }

    public PublishListing(listingId: string, parkData: PublishAdViewModel) {
        return this.POST_Request<any>(`${this.ApiUrl}/UserParkListing/PublishListing/${listingId}`, parkData);
    }

    public SaveImages(imagesData: ParkSiteImagesModel) {
        const data = new FormData();
        data.append('ParkId', imagesData.ParkId!);
        if (imagesData.Images) {
            for (let i = 0; i < imagesData.Images.length; i++) {
                const element = imagesData.Images[i];
                data.append(`Images`, element);
            }
        }
        if (imagesData.DeletedImageIds) {
            for (const Id of imagesData.DeletedImageIds) {
                data.append('DeletedImageIds', Id);
            }
        }
        return this.POST_FileRequest<ParkSiteImagesModel>(`${this.ApiUrl}/UserParkListing/AddListingImages`, data);
    }

    public SaveListingImages(imagesData: ParkListingImagesModel) {
        const data = new FormData();
        data.append('ParkId', imagesData.ParkId!);
        data.append('ListingId', imagesData.ListingId!);
        data.append('BrandingId', imagesData.BrandingId!);
        data.append('BrandingCompanyLogo', imagesData.BrandingCompanyLogo!);
        data.append('BrandingUserPicture', imagesData.BrandingUserPicture!);
        data.append('VideoThumbnail', imagesData.VideoThumbnail!);
        data.append('ParkDataId', imagesData.ParkDataId!);
        data.append('OldIsProfileBranding', `${imagesData.OldIsProfileBranding || false}`);

        if (!!imagesData.RemoveBrandingLogo) {
            data.append('RemoveBrandingLogo', `${!!imagesData.RemoveBrandingLogo}`);
        }
        if (!!imagesData.RemoveBrandingProfilePicture) {
            data.append('RemoveBrandingProfilePicture', `${!!imagesData.RemoveBrandingProfilePicture}`);
        }

        if (imagesData.ListingDocuments) {
            for (let i = 0; i < imagesData.ListingDocuments.length; i++) {
                const element = imagesData.ListingDocuments[i];
                data.append(`ListingDocuments`, element);
            }
        }
        if (imagesData.DeletedImageIds) {
            for (const Id of imagesData.DeletedImageIds) {
                data.append('DeletedImageIds', Id);
            }
        }
        if (imagesData.ListingDeletedImageIds) {
            for (const Id of imagesData.ListingDeletedImageIds) {
                data.append('ListingDeletedImageIds', Id);
            }
        }
        if (imagesData.OldImagesIds) {
            for (let index = 0; index < imagesData.OldImagesIds.length; index++) {
                data.append(`OldImagesIds[${index}].Id`, imagesData.OldImagesIds[index].Id);
                data.append(`OldImagesIds[${index}].Sequence`, `${imagesData.OldImagesIds[index].Sequence}`);
            }
        }
        if (imagesData.ListingOldMediaIds) {
            for (let index = 0; index < imagesData.ListingOldMediaIds.length; index++) {
                data.append(`ListingOldMediaIds[${index}].Id`, imagesData.ListingOldMediaIds[index].Id);
                data.append(`ListingOldMediaIds[${index}].Sequence`, `${imagesData.ListingOldMediaIds[index].Sequence}`);
            }
        }
        if (imagesData.ParkImages) {
            for (const image of imagesData.ParkImages) {
                data.append('ParkImages', image);
            }
        }
        if (imagesData.Sequences) {
            for (const sequence of imagesData.Sequences) {
                data.append('Sequences', `${sequence}`);
            }
        }
        if (imagesData.DocSequences) {
            for (const sequence of imagesData.DocSequences) {
                data.append('DocSequences', `${sequence}`);
            }
        }
        return this.POST_FileRequest<ParkListingImagesModel>(`${this.ApiUrl}/UserParkListing/AddListingImages`, data);
    }

    public AddListingView(data: ListingViewsViewModel) {
        return this.POST_Request<any>(`${this.ApiUrl}/UserParkListing/AddListingView`, data);
    }

    // public ChangeStatus(changeStatus: ChangeListingStatusModel) {
    //     return this.PUT_Request(`${this.ApiUrl}/UserParkListing/ChangeListingStatus`, changeStatus);
    // }
    // public update(parkListingData: AddParkListingViewModel) {
    //     return this.PUT_Request<AddParkListingViewModel>(`${this.ApiUrl}/UserParkListing`, parkListingData);
    // }
}
