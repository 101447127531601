import {ChangeMembershipViewModel, MembershipViewModel} from '@/sdk';
import {BaseApi} from '..';

export class MembershipApi extends BaseApi {
    public GetActiveMemberships() {
        return this.GET_Request<Array<MembershipViewModel>>(`${this.ApiUrl}/Membership/GetActiveMemberships`);
    }

    public ChangeMembership(data: ChangeMembershipViewModel) {
        return this.POST_Request<boolean>(`${this.ApiUrl}/Membership/ChangeMembership`, data);
    }
    public CancelMembership() {
        return this.GET_Request(`${this.ApiUrl}/Account/CancelMembership`);
    }
}
