import {Component, Prop, Vue} from 'nuxt-property-decorator';

@Component
export default class IndexThirdViewComponent extends Vue {
    @Prop({
        type: Array,
        required: true,
    })
    items!: Array<{title: string; icon: string; text: string,alt:string}>;
    
}