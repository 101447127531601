import {ParkInformationViewModel, ParkSiteFieldsViewModel} from '.';
import {CityModel} from '..';
import {ParkFieldViewModel} from './park-field-view.model';

export class SaveParkViewModel {
    public Id?: string | null = null;
    public Latitude: number | null = null;
    public Longitude: number | null = null;
    public Address: string | null = null;
    public City: string | null = null;
    public State: string | null = null;
    public County: string | null = null;
    public Country: string | null = null;
    public Zip: string | null = null;
    public CityData: CityModel | null = null;

    public LastUpdatedBy: string | null = null;
    public LastUpdatedDate: Date | null = null;

    // public EntryCount: number | null = null;
    public Status: string | null = null;

    public Active?: boolean | null = null;
    public ClaimStatus?: boolean | null = null;

    public IsClaimedByMe?: boolean | null = null;

    public Created: Date | null = null;

    public ParkInfo: ParkInformationViewModel | null = null;

    public ParkFieldsDataObj?: ParkSiteFieldsViewModel | null = null;

    public ParkFieldsData: Array<ParkFieldViewModel> | null = null;

    public Slug: string | null = null;

    constructor(data?: Partial<SaveParkViewModel>) {
        Object.assign(this, data);
        if (!this.ParkInfo) {
            this.ParkInfo = new ParkInformationViewModel();
        }
        if (!this.ParkFieldsDataObj) {
            this.ParkFieldsDataObj = new ParkSiteFieldsViewModel();
        }

        // this.ParkFieldsData = new Array<KeyValueModel>();
    }
}
