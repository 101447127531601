import { render, staticRenderFns } from "./error.vue?vue&type=template&id=d2478926&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=d2478926&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2478926",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PaxIntroCard: require('C:/agent/_work/4/s/components/base/pax-intro-card/pax-intro-card.vue').default,IntroductoryComponent: require('C:/agent/_work/4/s/components/base/introductory-component/introductory-component.vue').default,PromoCard: require('C:/agent/_work/4/s/components/shared/promo-card/promo-card.vue').default,InfoCard: require('C:/agent/_work/4/s/components/shared/info-card/info-card.vue').default,ExploreCard: require('C:/agent/_work/4/s/components/shared/explore-card/explore-card.vue').default,SignupCard: require('C:/agent/_work/4/s/components/shared/signup-card/signup-card.vue').default,IndexThirdViewComponent: require('C:/agent/_work/4/s/components/main-layout-new-design/index/Third-view/index-third-view.component.vue').default,BaseDividerComponent: require('C:/agent/_work/4/s/components/shared/base-divider/base-divider.component.vue').default,AdvertiseCard: require('C:/agent/_work/4/s/components/shared/advertise-card/advertise-card.vue').default})
