import {ServiceClass} from '@/decorators';
import {AdsApi} from '@/sdk/api-services';
import {AdHistoryViewModel, AdsModel, ListAdViewModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';
import { AD_PRINCIPAL_TYPE } from '~/globals';

@ServiceClass()
export class AdsService {
    public Ads = new BehaviorSubject<Array<ListAdViewModel>>([]);
    public Filter = new FilterModel({Status: 'ALL'});
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    public historyList: Array<AdHistoryViewModel> = [];
    TotalRowsHistory: number = 0;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;

        this.getAll();
    };

    public getAll() {
        new LoaderService().showFullScreenLoader();
        new AdsApi().getAll(this.Filter).subscribe(
            ({Data}) => {
                new LoaderService().hideFullScreenLoader();
                this.Ads.next(Data?.Data ?? []);
                this.TotalRows = Data?.TotalRows || 0;
            },
            () => {
                new LoaderService().hideFullScreenLoader();
            }
        );
    }

    public GetAdHistory(adId: string) {
        return new Promise((resolve, reject) => {
            new LoaderService().showFullScreenLoader();
            new AdsApi().GetAdHistory(adId).subscribe(
                ({Data}) => {
                    new LoaderService().hideFullScreenLoader();
                    this.historyList = Data?.Data ?? [];
                    this.TotalRowsHistory = Data?.TotalRows || 0;

                    resolve(Data);
                },
                () => {
                    new LoaderService().hideFullScreenLoader();
                    reject();
                }
            );
        });
    }
    public AddOrRemoveUserFavAd(AdId: string, AdType: AD_PRINCIPAL_TYPE) {
        return new AdsApi().userFavoriteAdToggle(AdId, AdType);
    }
}
