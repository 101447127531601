import {ServiceClass} from '@/decorators';
import {AdvertiseParkPermissionViewModel} from '@/sdk/models';

import {BaseApi} from '../base.api';

@ServiceClass()
export class AdvertiseParkPermissionApi extends BaseApi {
    public RequestAdvertisePermmission(parkId: string) {
        return this.GET_Request<AdvertiseParkPermissionViewModel>(`${this.ApiUrl}/AdvertiseParkPermission/RequestAdvertisePermmission/${parkId}`);
    }
}
