import { Component,Prop,Vue } from 'nuxt-property-decorator';
import { LoaderService } from '~/sdk';

@Component
export default class IntroductoryComponent extends Vue {
    @Prop({
        default:'/images/new-layout/pax-web-2.png',
        type:String
    })
    public src!:string;
    
    mounted() {
        new LoaderService().showFullScreenLoader();
        setTimeout(() => {
            new LoaderService().hideFullScreenLoader();
        },2000)

    }

    get imageAlt(){
        const dummyArray:Array<string> = this.src.split('/')
        const substr:string = dummyArray[dummyArray.length - 1]
        return substr.slice(0,substr.indexOf('.'))
    }
    get minHeight(){
        return this.$vuetify.breakpoint.mdAndUp ? '400' : '180';
    }
    get minWidth(){
        if((this.$vuetify.breakpoint.width > 960 && this.$vuetify.breakpoint.width < 1105)  && this.doesLessSpacingLinkExists){
            return '405';
        }
        return this.$vuetify.breakpoint.width > 1105 ? '550' : '140';
    }

    public listOfLessSpacingLinks:Array<string> = [
        'park-sites-solution',
        'advertise-solution',
        'contacts-crm-solution',
        'sold-parks-solution',
        'my-parks-solution',
        'professionals-solution',
        'schedule-support'
    ]

    get doesLessSpacingLinkExists() :boolean {
        return this.listOfLessSpacingLinks.includes(this.$route.name!);
    }
}