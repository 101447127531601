export class ContactSupportModel {
    public FirstName: string | null = null;
    public LastName: string | null = null;
    public Email: string | null = null;
    public PhoneNo: string | null = null;
    public Description: string | null = null;
    public RecaptchaResponse: string | null = null;

    constructor(data?: Partial<ContactSupportModel>) {
        Object.assign(this, data);
    }
}
