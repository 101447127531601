export class ParkInformationViewModel {
    public Id: string | null = null;

    public PermitNo: string | null = null;
    public Unregistered: boolean = false;

    public YearBuilt: string | null = null;
    public YearBuiltUnknown: boolean = false;

    public TotalAcres: number | null = null;
    public TotalAcresUnknown: boolean = false;

    public ParkName: string | null = null;
    public StreetAddress: string | null = null;

    public ParkPhone: string | null = null;
    public PhoneNumberUnknow: boolean = false;

    public WebSite: string | null = null;
    public ParkSiteId: string | null = null;
    // public ParkSite ParkSite : string | null = null;
    public EntryCount: number | null = null;

    constructor(data?: Partial<ParkInformationViewModel>) {
        Object.assign(this, data);
    }
}
