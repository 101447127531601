import {ServiceClass} from '@/decorators';
import {parseJwt} from '@/globals';
import {AccountsApi} from '@/sdk/api-services';
import {UserSession} from '@/sdk/core';
import {AlertService} from '../shared/alert.service';

@ServiceClass()
export class AccountService {
    public refreshTokenTimeout: any = null;

    constructor() {
        //
    }

    public setRefreshTokenCycle() {
        this.clearTimeout();
        const ms = this.validateJwtTime();

        console.log('token refresh time', ms);
        if (ms) {
            this.refreshTokenTimeout = setTimeout(() => {
                console.log('refresh token timeout called');
                this.refreshToken();
            }, ms);
        } else {
            const exp = this.jwtExpireTime();
            if (exp && exp - 10 * 60 * 1000 > 0) {
                setTimeout(() => {
                    this.refreshToken();
                }, 1000);
            }
            this.clearTimeout();
        }
    }

    public refreshToken() {
        new AccountsApi().RegenerateToken().subscribe(
            res => {
                if (res.Status && res.Data?.Token) {
                    new UserSession().setJwt(res.Data.Token);
                    this.setRefreshTokenCycle();
                } else {
                    new UserSession().clear();
                    new AlertService().show('info', 'You session has been expired. Please login again.').then(() => {
                        window.location.href = '/';
                    });
                }
            },
            err => {
                new UserSession().clear();
                // window.location.href = '/';
                new AlertService().show('info', 'You session has been expired. Please login again.').then(() => {
                    window.location.href = '/';
                });
            }
        );
    }

    private clearTimeout() {
        if (this.refreshTokenTimeout) {
            clearTimeout(this.refreshTokenTimeout);
        }
    }

    private jwtExpireTime() {
        const jwt = new UserSession().Session?.JwtToken;

        const jwtData: any = jwt && parseJwt(jwt);

        return jwtData?.exp ? jwtData.exp * 1000 : 0;
    }

    private validateJwtTime() {
        const exp = this.jwtExpireTime();

        if (exp > 0) {
            const tenMinBefore = new Date(exp).valueOf() - (1 * 60 * 60 * 1000 + 0 * 30 * 60 * 1000);

            if (tenMinBefore > new Date().valueOf()) {
                return tenMinBefore - new Date().valueOf();
            }
        }

        return false;
    }
}
