import {BaseApi, ParksInAreaSpViewModel} from '@/sdk';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {
    DynamicPagesInfoModel,
    GetParkSiteImagesModel,
    ParkSiteDataViewModel,
    ParkSiteImagesModel,
    ParkSiteViewModel,
    RequestNewParkSiteDataModel,
    SaveParkViewModel,
} from '@/sdk/models/park-site';
import {ParkClaimHolderModel} from '@/sdk/models/park-site/park-claim-holder.model';
import {ParkHistoryDataModel as ParkActivityHistoryModel} from '@/sdk/models/park-site/park-data-history.model';

export class ParkSiteApi extends BaseApi {
    public AddNewPark(park: ParkSiteViewModel) {
        return this.POST_Request<ParkSiteViewModel>(`${this.ApiUrl}/ParkSite/AddNewPark`, park);
    }

    public GetParksInArea(
        swlat: number,
        swlng: number,
        nelat: number,
        nelng: number,
        status: string | null = 'TRUE',
        stateIds: Array<string> = [],
        parkType: string | null = null,
        legendTypes: string | null = null
    ) {
        return this.GET_Request<Array<ParksInAreaSpViewModel>>(
            `${this.ApiUrl}/ParkSite/GetParksInArea?swlat=${swlat}&swlng=${swlng}&nelat=${nelat}&nelng=${nelng}&Status=${status}&stateIds=${stateIds.join(
                ','
            )}&parkType=${parkType ?? ''}&legendTypes=${legendTypes ?? ''}`
        );
    }

    public GetParkById(ParkId: string) {
        return this.GET_Request<SaveParkViewModel>(`${this.ApiUrl}/ParkSite/GetParkById/${ParkId}`);
    }
    public GetParkSiteCount() {
        return this.GET_Request<number>(`${this.ApiUrl}/Dashboard/GetParkCount`);
    }

    public GetAllSlugs() {
        return this.GET_Request<Array<string>>(`${this.ApiUrl}/ParkSite/GetAllSlugs`);
    }

    public GetParkData(ParkId: string) {
        return this.GET_Request<ParkSiteDataViewModel>(`${this.ApiUrl}/ParkSite/GetParkData/${ParkId}`);
    }
    public DynamicPagesInfo(parkId: string) {
        return this.GET_Request<DynamicPagesInfoModel>(`${this.ApiUrl}/ParkSite/GetParkDetailsForPublicSiteByParkId/${parkId}`);
    }
    public GetParkDetails(ParkId: string) {
        return this.GET_Request<SaveParkViewModel>(`${this.ApiUrl}/ParkSite/GetParkDetails/${ParkId}`);
    }

    public GetParkHolderDetails(ParkId: string) {
        return this.GET_Request<ParkClaimHolderModel>(`${this.ApiUrl}/MyParkSite/ClaimHolderinfo/${ParkId}`);
    }

    public UpdateParkLocation(ParkId: string, data: ParkSiteViewModel) {
        return this.POST_Request<SaveParkViewModel>(`${this.ApiUrl}/ParkSite/UpdateParkLocation/${ParkId}`, data);
    }

    public RequestNewParkSite(UserId: string, data: RequestNewParkSiteDataModel) {
        const formData = new FormData();
        formData.append('Latitude', `${data.Latitude!}`);
        formData.append('Longitude', `${data.Longitude!}`);
        formData.append('Message', data.Message!);

        formData.append('File', data.file!);

        return this.POST_FileRequest<boolean>(`${this.ApiUrl}/ParkSite/RequestNewParkSite/${UserId}`, formData);
    }

    public ToggleActive(ParkId: string) {
        return this.PUT_Request<boolean>(`${this.ApiUrl}/ParkSite/ToggleStatus/${ParkId}`, {});
    }

    public GetParkImages(ParkId: string) {
        return this.GET_Request<Array<GetParkSiteImagesModel>>(`${this.ApiUrl}/ParkSite/GetParkImages/${ParkId}`);
    }
    public GetParkActivityHistory(ParkId: string) {
        const filter = new FilterModel({
            PageSize: -1,
            SortDesc: true,
        });

        return this.GET_Request<Array<ParkActivityHistoryModel>>(`${this.ApiUrl}/ParkSite/GetParkActivityHistory/${ParkId}?filter=${JSON.stringify(filter)}`);
    }

    public SaveParkData(id: string, parkData: SaveParkViewModel) {
        return this.POST_Request<SaveParkViewModel>(`${this.ApiUrl}/ParkSite/SaveParkSite/${id}`, parkData);
    }

    public SaveImages(imagesData: ParkSiteImagesModel) {
        const data = new FormData();
        data.append('ParkId', imagesData.ParkId!);
        if (imagesData.Images) {
            for (let i = 0; i < imagesData.Images.length; i++) {
                const element = imagesData.Images[i];
                data.append(`Images`, element);
            }
        }
        if (imagesData.DeletedImageIds) {
            for (const Id of imagesData.DeletedImageIds) {
                data.append('DeletedImageIds', Id);
            }
        }
        if (imagesData.Sequences) {
            for (const sequence of imagesData.Sequences) {
                data.append('Sequences', `${sequence}`);
            }
        }
        if (imagesData.OldImagesIds) {
            for (let index = 0; index < imagesData.OldImagesIds.length; index++) {
                data.append(`OldImagesIds[${index}].Id`, imagesData.OldImagesIds[index].Id);
                data.append(`OldImagesIds[${index}].Sequence`, `${imagesData.OldImagesIds[index].Sequence}`);
            }
        }

        return this.POST_FileRequest<ParkSiteImagesModel>(`${this.ApiUrl}/ParkSite/SaveParkImages`, data);
    }
}
