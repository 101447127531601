import { render, staticRenderFns } from "./client-app-bar.component.vue?vue&type=template&id=286d70b2&scoped=true&"
import script from "./client-app-bar.component.ts?vue&type=script&lang=ts&"
export * from "./client-app-bar.component.ts?vue&type=script&lang=ts&"
import style0 from "./client-app-bar.component.vue?vue&type=style&index=0&id=286d70b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286d70b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('C:/agent/_work/4/s/components/vuetify/base-btn/base-btn.vue').default})
