import {ServiceClass} from '@/decorators';
import {ParkContactAPi, ParkContactModel} from '@/sdk';
import {QuickLinksApi} from '@/sdk/api-services/quick-links/quick-links.api';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {QuickLinksModel} from '@/sdk/models/quick-links/quick-links.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class ParkContactService {
    public ParkContacts: Array<ParkContactModel> = Array<ParkContactModel>();
    public ParkContact = new ParkContactModel();
    public ParkId: string | null = null;
    public Filter: FilterModel = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
    };

    public getAll(id: string) {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            new ParkContactAPi()
                .getAll(this.Filter, id)
                .subscribe(({Data}) => {
                    if (Data) {
                        this.ParkContacts = Data.Data ?? [];
                        this.TotalRows = Data?.TotalRows || 0;
                        console.log(Data);
                    }
                })
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                    resolve(null);
                });
        });
    }

    public getById(id: string) {
        return new Promise((resolve, reject) => {
            new LoaderService().showFullScreenLoader();

            new ParkContactAPi()
                .getById(id)
                .subscribe(
                    ({Data}) => {
                        if (Data) {
                            this.ParkContact = Data;
                            resolve(Data);
                        }
                    },
                    err => {
                        reject(err);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }
}
