import {ParksInAreaSpViewModel} from '@/sdk';
import {ParkSiteViewModel} from '@/sdk/models/park-site';

export interface ShowableRoute {
    Name?: string;
    Icon: string;
    Title: string;
    Chip: string;
    Color: string;
    Position?: number;
}

export class AnyObject {
    [key: string]: any;
}

export interface PinObj {
    lat: number | null;
    lng: number | null;
    type: string | null;
    askingPrice?: number | null;
    park?: ParkSiteViewModel | null;
    parkSp?: ParksInAreaSpViewModel | null;
}

export interface InfoWindowLinkType {
    title: string;
    link: string;
    key: string;
}
