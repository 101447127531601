import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _605e30b3 = () => interopDefault(import('..\\pages\\blog-articles.vue' /* webpackChunkName: "pages/blog-articles" */))
const _8ca53412 = () => interopDefault(import('..\\pages\\cancelled-membership.vue' /* webpackChunkName: "pages/cancelled-membership" */))
const _ce368c1a = () => interopDefault(import('..\\pages\\case-studies\\index.vue' /* webpackChunkName: "pages/case-studies/index" */))
const _52392403 = () => interopDefault(import('..\\pages\\change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _0e0d29db = () => interopDefault(import('..\\pages\\classified-ads.vue' /* webpackChunkName: "pages/classified-ads" */))
const _2da52160 = () => interopDefault(import('..\\pages\\contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _7566ad80 = () => interopDefault(import('..\\pages\\disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _31d17d18 = () => interopDefault(import('..\\pages\\expired-professional.vue' /* webpackChunkName: "pages/expired-professional" */))
const _3ab1f0ba = () => interopDefault(import('..\\pages\\learn-more.vue' /* webpackChunkName: "pages/learn-more" */))
const _6f74a2d8 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _9cf8db74 = () => interopDefault(import('..\\pages\\meet-pax.vue' /* webpackChunkName: "pages/meet-pax" */))
const _3e343c3a = () => interopDefault(import('..\\pages\\our-team.vue' /* webpackChunkName: "pages/our-team" */))
const _63c6be5c = () => interopDefault(import('..\\pages\\park-for-sale-ads.vue' /* webpackChunkName: "pages/park-for-sale-ads" */))
const _1b08efb1 = () => interopDefault(import('..\\pages\\pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _4fd14e7c = () => interopDefault(import('..\\pages\\privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _9722ec52 = () => interopDefault(import('..\\pages\\professionals.vue' /* webpackChunkName: "pages/professionals" */))
const _e70886a2 = () => interopDefault(import('..\\pages\\resend-email.vue' /* webpackChunkName: "pages/resend-email" */))
const _7317e9be = () => interopDefault(import('..\\pages\\schedule-support.vue' /* webpackChunkName: "pages/schedule-support" */))
const _37c0fa16 = () => interopDefault(import('..\\pages\\sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _773c24c6 = () => interopDefault(import('..\\pages\\solutions\\index.vue' /* webpackChunkName: "pages/solutions/index" */))
const _aec86c9c = () => interopDefault(import('..\\pages\\terms.vue' /* webpackChunkName: "pages/terms" */))
const _03d3a07e = () => interopDefault(import('..\\pages\\trial.vue' /* webpackChunkName: "pages/trial" */))
const _05a4a3f3 = () => interopDefault(import('..\\pages\\video-tips\\index.vue' /* webpackChunkName: "pages/video-tips/index" */))
const _183934de = () => interopDefault(import('..\\pages\\solutions\\advertise-solution\\index.vue' /* webpackChunkName: "pages/solutions/advertise-solution/index" */))
const _4449cd56 = () => interopDefault(import('..\\pages\\solutions\\contacts-crm-solution\\index.vue' /* webpackChunkName: "pages/solutions/contacts-crm-solution/index" */))
const _64dafa4e = () => interopDefault(import('..\\pages\\solutions\\my-parks-solution\\index.vue' /* webpackChunkName: "pages/solutions/my-parks-solution/index" */))
const _7e4cd11a = () => interopDefault(import('..\\pages\\solutions\\park-sites-solution\\index.vue' /* webpackChunkName: "pages/solutions/park-sites-solution/index" */))
const _3249243a = () => interopDefault(import('..\\pages\\solutions\\professionals-solution\\index.vue' /* webpackChunkName: "pages/solutions/professionals-solution/index" */))
const _9a94fe7e = () => interopDefault(import('..\\pages\\solutions\\sold-parks-solution\\index.vue' /* webpackChunkName: "pages/solutions/sold-parks-solution/index" */))
const _6fa2718f = () => interopDefault(import('..\\pages\\case-studies\\assessing-the-county-assessor\\assessing-the-county-assessor.vue' /* webpackChunkName: "pages/case-studies/assessing-the-county-assessor/assessing-the-county-assessor" */))
const _ac46a4b6 = () => interopDefault(import('..\\pages\\case-studies\\cold-calling\\cold-calling.vue' /* webpackChunkName: "pages/case-studies/cold-calling/cold-calling" */))
const _1274eb92 = () => interopDefault(import('..\\pages\\solutions\\classified-ads\\_adId.vue' /* webpackChunkName: "pages/solutions/classified-ads/_adId" */))
const _152d9988 = () => interopDefault(import('..\\pages\\solutions\\park-for-sale-ads\\_listingId.vue' /* webpackChunkName: "pages/solutions/park-for-sale-ads/_listingId" */))
const _5ae38eba = () => interopDefault(import('..\\pages\\video-tips\\video-tips-detail\\_videoTip.vue' /* webpackChunkName: "pages/video-tips/video-tips-detail/_videoTip" */))
const _406f43c0 = () => interopDefault(import('..\\pages\\blogs\\_blogSlug.vue' /* webpackChunkName: "pages/blogs/_blogSlug" */))
const _e44f1f6e = () => interopDefault(import('..\\pages\\professional-details\\_id.vue' /* webpackChunkName: "pages/professional-details/_id" */))
const _02c3e906 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _424e79c4 = () => interopDefault(import('..\\pages\\landing\\park-sites\\_.vue' /* webpackChunkName: "" */))
const _5ec033bb = () => interopDefault(import('..\\pages\\landing\\park-ad\\_.vue' /* webpackChunkName: "" */))
const _4d888a65 = () => interopDefault(import('..\\pages\\landing\\my-parks\\_.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog-articles",
    component: _605e30b3,
    name: "blog-articles"
  }, {
    path: "/cancelled-membership",
    component: _8ca53412,
    name: "cancelled-membership"
  }, {
    path: "/case-studies",
    component: _ce368c1a,
    name: "case-studies"
  }, {
    path: "/change-password",
    component: _52392403,
    name: "change-password"
  }, {
    path: "/classified-ads",
    component: _0e0d29db,
    name: "classified-ads"
  }, {
    path: "/contact-us",
    component: _2da52160,
    name: "contact-us"
  }, {
    path: "/disclaimer",
    component: _7566ad80,
    name: "disclaimer"
  }, {
    path: "/expired-professional",
    component: _31d17d18,
    name: "expired-professional"
  }, {
    path: "/learn-more",
    component: _3ab1f0ba,
    name: "learn-more"
  }, {
    path: "/login",
    component: _6f74a2d8,
    name: "login"
  }, {
    path: "/meet-pax",
    component: _9cf8db74,
    name: "meet-pax"
  }, {
    path: "/our-team",
    component: _3e343c3a,
    name: "our-team"
  }, {
    path: "/park-for-sale-ads",
    component: _63c6be5c,
    name: "park-for-sale-ads"
  }, {
    path: "/pricing",
    component: _1b08efb1,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _4fd14e7c,
    name: "privacy-policy"
  }, {
    path: "/professionals",
    component: _9722ec52,
    name: "professionals"
  }, {
    path: "/resend-email",
    component: _e70886a2,
    name: "resend-email"
  }, {
    path: "/schedule-support",
    component: _7317e9be,
    name: "schedule-support"
  }, {
    path: "/sign-up",
    component: _37c0fa16,
    name: "sign-up"
  }, {
    path: "/solutions",
    component: _773c24c6,
    name: "solutions"
  }, {
    path: "/terms",
    component: _aec86c9c,
    name: "terms"
  }, {
    path: "/trial",
    component: _03d3a07e,
    name: "trial"
  }, {
    path: "/video-tips",
    component: _05a4a3f3,
    name: "video-tips"
  }, {
    path: "/solutions/advertise-solution",
    component: _183934de,
    name: "solutions-advertise-solution"
  }, {
    path: "/solutions/contacts-crm-solution",
    component: _4449cd56,
    name: "solutions-contacts-crm-solution"
  }, {
    path: "/solutions/my-parks-solution",
    component: _64dafa4e,
    name: "solutions-my-parks-solution"
  }, {
    path: "/solutions/park-sites-solution",
    component: _7e4cd11a,
    name: "solutions-park-sites-solution"
  }, {
    path: "/solutions/professionals-solution",
    component: _3249243a,
    name: "solutions-professionals-solution"
  }, {
    path: "/solutions/sold-parks-solution",
    component: _9a94fe7e,
    name: "solutions-sold-parks-solution"
  }, {
    path: "/case-studies/assessing-the-county-assessor/assessing-the-county-assessor",
    component: _6fa2718f,
    name: "case-studies-assessing-the-county-assessor-assessing-the-county-assessor"
  }, {
    path: "/case-studies/cold-calling/cold-calling",
    component: _ac46a4b6,
    name: "case-studies-cold-calling-cold-calling"
  }, {
    path: "/solutions/classified-ads/:adId?",
    component: _1274eb92,
    name: "solutions-classified-ads-adId"
  }, {
    path: "/solutions/park-for-sale-ads/:listingId?",
    component: _152d9988,
    name: "solutions-park-for-sale-ads-listingId"
  }, {
    path: "/video-tips/video-tips-detail/:videoTip",
    component: _5ae38eba,
    name: "video-tips-video-tips-detail-videoTip"
  }, {
    path: "/blogs/:blogSlug?",
    component: _406f43c0,
    name: "blogs-blogSlug"
  }, {
    path: "/professional-details/:id?",
    component: _e44f1f6e,
    name: "professional-details-id"
  }, {
    path: "/",
    component: _02c3e906,
    name: "index"
  }, {
    path: "/blog-articles/:_blogSlug",
    component: _406f43c0,
    name: "show-blog-post"
  }, {
    path: "/park-for-sale-ads/:state/:title/:listingId",
    component: _152d9988,
    name: "park-for-sale-ads-listingId"
  }, {
    path: "/classified-ads/:state/:category/:title/:adId",
    component: _1274eb92,
    name: "classified-ads-adId"
  }, {
    path: "/case-studies/assessing-the-county-assessor",
    component: _6fa2718f,
    name: "assessing-the-county-assessor"
  }, {
    path: "/case-studies/cold-calling-myth-case-study",
    component: _ac46a4b6,
    name: "cold-calling"
  }, {
    path: "/video-tips/:title",
    component: _5ae38eba,
    name: "video-tips-detail"
  }, {
    path: "/landing/park-sites/:state/:parkName/:parkId",
    component: _424e79c4,
    name: "park-sites-listing"
  }, {
    path: "/landing/advertise/:state/:parkName/:parkId/ad",
    component: _5ec033bb,
    name: "advertise-parks-listing-ad"
  }, {
    path: "/landing/my-parks/:state/:parkName/:parkId",
    component: _4d888a65,
    name: "my-parks-listing"
  }, {
    path: "/pricing/free-trial",
    component: _03d3a07e,
    name: "free-trial"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
