export class ListingViewsViewModel {
    public Name: string | null = null;
    public Email: string | null = null;
    public PhoneNumber: string | null = null;
    public Browser: string | null = null;
    public OS: string | null = null;
    public DateVisited: Date | null = null;
    public IPAddress: string | null = null;
    public UserId: string | null = null;
    public ListingId: string | null = null;
    public AdId: string | null = null;

    public constructor(data?: Partial<ListingViewsViewModel>) {
        Object.assign(this, data);
    }
}
