import {AnyObject} from '~/globals';

export class MailerLitePayLoadModel {
    public Email: string | null = null;
    public Fields: MailerLiteFieldsModel | AnyObject = {};
    constructor(data?: Partial<MailerLitePayLoadModel>) {
        Object.assign(this, data);
    }
}

export class MailerLiteFieldsModel {
    public role: string | null = null;
    public source: string | null = null;
}
