import {ServiceClass} from '@/decorators';
import {helpers} from '@/plugins/helpers/helpers';
import {AlertService, ConfirmService, ListingViewsViewModel} from '@/sdk';
import {AdsApi, ClassifiedApi} from '@/sdk/api-services';
import {AdsModel, ClassifiedAdModel} from '@/sdk/models';
import {FilterModel, ListingFilterViewModel} from '@/sdk/models/common/filter.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {PaginationService} from '..';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class ClassifiedAdsService {
    public Ads: Array<ClassifiedAdModel> = Array<ClassifiedAdModel>();
    public ClassifiedAdsByCategory: Array<ClassifiedAdModel> = Array<ClassifiedAdModel>();
    public ClassifiedAd: ClassifiedAdModel = new ClassifiedAdModel();

    public SelectedClassifiedAd: ClassifiedAdModel | null = null;

    public Filter = new FilterModel();
    TotalRows: number = 0;
    ByCategoryRows: number = 0;
    public options: DataOptions = {
        itemsPerPage: this.Filter.PageSize,
    } as DataOptions;

    public classifiedViewsFilter = new FilterModel({
        SortDesc: true,
    });

    public listingViews: Array<ListingViewsViewModel> = [];
    public totalListingViews: number | null = null;

    public categories = [
        {title: 'Mobile Homes for Sale', id: '80cb5954-abf3-430e-f05b-08d932309d91',color:'#3498DB'},
        {title: 'RVs for Sale', id: '80cb5954-abf3-430e-f05b-08d932309d92',color:'#D35400'},
        {title: 'Wholesale Parks', id: 'f4f6a8ce-2d15-440d-a307-996e852f1195',color:'#8c9096'},
        {title: 'Brokerage Services', id: '80cb5954-abf3-430e-f05b-08d932309d99',color:'#00145E'},
        {title: 'Insurance Services', id: '80cb5954-abf3-430e-f05b-08d932309d98',color:'#2C3E50'},
        {title: 'Wholesale Services', id: '34888e34-368d-4892-b3a2-17a093bda078',color:'#4CAF50'},
        {title: 'Coaching Services', id: '80cb5954-abf3-430e-f05b-08d932309d97',color:'#8E44AD'},
        {title: 'Lending Services', id: '81cb5954-abf3-430e-f05b-08d932309d95',color:'#F7A608'},
        {title: 'In Search Of', id: '80cb5954-abf3-430e-f05b-08d932309d96',color:'#FF0000'},
    ];

    constructor() {
        // this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;

        this.GetUserClassifiedAds();
    };

    ListingViewsFilterData = (event: DataOptions) => {
        this.classifiedViewsFilter.PageNo = event.page;
        this.classifiedViewsFilter.PageSize = event.itemsPerPage;
        this.classifiedViewsFilter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.classifiedViewsFilter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;

        if (this.classifiedViewsFilter.SortBy) {
            this.classifiedViewsFilter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
        } else {
            this.classifiedViewsFilter.SortDesc = true;
        }
    };

    public resetForm() {
        this.ClassifiedAd = new ClassifiedAdModel();
    }

    public GetClassifiedViews(filter?: FilterModel) {
        new LoaderService().showFullScreenLoader();
        new ClassifiedApi()
            .GetAdViews(filter)
            .subscribe(res => {
                this.listingViews = res.Data?.Data ?? [];
                this.totalListingViews = res.Data?.TotalRows ?? 0;
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public GetUserClassifiedAds() {
        return new Promise((resolve, reject) => {
            new LoaderService().showFullScreenLoader();
            new ClassifiedApi()
                .GetUserClassifiedAds(this.Filter)
                .subscribe(
                    ({Data}) => {
                        this.Ads.splice(0, this.Ads.length);
                        this.Ads.push(...(Data?.Data || []));
                        this.TotalRows = Data?.TotalRows || 0;
                        resolve(Data);
                    },
                    error => {
                        reject(error);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    // public PublishClassified(id: string) {
    //     return new Promise(resolve => {
    //         new ConfirmService().open('Publish Ad', `Are you sure you wan't to publish this Ad?`).then(val => {
    //             if (val) {
    //                 new LoaderService().showFullScreenLoader();
    //                 new ClassifiedApi()
    //                     .PublishClassified(id)
    //                     .subscribe(
    //                         res => {
    //                             if (res.Status) {
    //                                 new AlertService().show(
    //                                     'success',
    //                                     `Congratulations, your Classified Ad is now published! We are currently sending out a notification to all of our users.
    //                                      Your ad will be displayed on our public website until <%Ad Expiration Date%>.`
    //                                 );
    //                                 resolve(true);
    //                             } else {
    //                                 new AlertService().show('warning', 'Unable to publish Ad.');
    //                                 resolve(false);
    //                             }
    //                         },
    //                         err => {
    //                             new AlertService().show('error', err.message || err.Message);
    //                             resolve(false);
    //                         }
    //                     )
    //                     .add(() => {
    //                         new LoaderService().hideFullScreenLoader();
    //                         this.GetUserClassifiedAds();
    //                     });
    //             }
    //         });
    //     });
    // }

    public GetClassifiedAd(id: string) {
        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            new ClassifiedApi()
                .GetUserClassifiedAdById(id)
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.ClassifiedAd = new ClassifiedAdModel(res.Data);
                            if (this.ClassifiedAd.PublishedDate) {
                                this.ClassifiedAd.PublishedDate = helpers.formatDate(this.ClassifiedAd.PublishedDate, 'yyyy-MM-dd');
                            }
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    },
                    err => {
                        new AlertService().show('error', err || 'Unable to load Ad');
                        resolve(false);
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                });
        });
    }

    public GetAdsByCategory(filter: FilterModel, listingFilter?: ListingFilterViewModel) {
        new LoaderService().showFullScreenLoader();
        new ClassifiedApi()
            .GetAdsByCategory(filter, listingFilter)
            .subscribe(({Data}) => {
                if (Data) {
                    this.ClassifiedAdsByCategory.splice(0, this.ClassifiedAdsByCategory.length);
                    this.ClassifiedAdsByCategory.push(...(Data?.Data || []));
                    this.ByCategoryRows = Data.TotalRows;
                    new PaginationService().TotalPages = Data.TotalRows;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
