import {ServiceClass} from '@/decorators';
import {CountyApi} from '@/sdk/api-services';
import {CountyModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class CountyService {
    public Counties = new BehaviorSubject<Array<CountyModel>>([]);
    public TempCounty = new BehaviorSubject(new CountyModel());
    public Filter = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
    };

    public getCountyByState(id?: string, all: boolean = false) {
        if (all) {
            this.Filter.PageSize = -1;
        }
        new LoaderService().showFullScreenLoader();
        new CountyApi()
            .getCountyByState(id, this.Filter)
            .subscribe(({Data}) => {
                if (Data) {
                    this.Counties.next(Data.Data ?? []);
                    this.TotalRows = Data?.TotalRows || 0;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();

        new CountyApi()
            .getById(id)
            .subscribe(({Data}) => {
                if (Data) {
                    this.TempCounty.next(Data);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
