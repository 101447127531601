import {Component, Prop} from 'vue-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import {AnyObject} from '~/globals';

@Component
export default class InfoCardComponent extends VueWrapper {
    @Prop({
        type: String,
        required: true,
    })
    tag!: string;

    @Prop({
        type: String,
        required: true,
    })
    description!: string;

    @Prop({
        type: String,
        required: true,
    })
    btnText!: string;

    @Prop({
        type: String,
        required: true,
    })
    link!: string;
}
