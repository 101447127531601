export class ParkListingImagesModel {
    public Id?: string | null = null;
    public ListingId: string | null = null;
    public ParkId: string | null = null;
    public BrandingId: string | null = null;
    public ParkDataId: string | null = null;
    public BrandingCompanyLogo: File | null = null;
    public BrandingUserPicture: File | null = null;
    public ListingDocuments: Array<File> | null = null;
    public VideoThumbnail: File | null = null;
    public DeletedImageIds: Array<string> | null = null;
    public OldImagesIds: Array<{Id: string; Sequence: number}> | null = null;
    public ListingOldMediaIds: Array<{Id: string; Sequence: number}> | null = null;
    public ParkImages: Array<File> | null = null;
    public Sequences: Array<number> | null = null;
    public DocSequences: Array<number> | null = null;
    public ListingDeletedImageIds: Array<string> | null = null;

    public RemoveBrandingLogo: boolean | null = null;
    public RemoveBrandingProfilePicture: boolean | null = null;
    public OldIsProfileBranding: boolean | null = null;

    constructor(data?: Partial<ParkListingImagesModel>) {
        Object.assign(this, data);
    }
}
