import {Component, Prop} from 'vue-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import {AnyObject} from '~/globals';

@Component
export default class SignupCardComponent extends VueWrapper {
    @Prop({
        type: String,
        default: 'Receive Ad Alerts in Your Inbox',
    })
    title!: string;

    @Prop({
        type: String,
        default:
            'Sign and we’ll provide you with a free forever Standard Plan which includes alerts when members post mobile home parks for sale or a classified ad.  Manage your alerts from within your profile settings.',
    })
    description!: string;
}
