import {AdsModel, AdsSuspendModel, PaginationModel, ContactSellerModel, ListAdViewModel, AdHistoryViewModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {QuickLinksModel} from '@/sdk/models/quick-links/quick-links.model';
import {BaseApi} from '../base.api';
import {AD_PRINCIPAL_TYPE} from '~/globals';

export class AdsApi extends BaseApi {
    public getAll(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<ListAdViewModel>>(`${this.ApiUrl}/UserAds?filter=${JSON.stringify(filter)}`);
    }

    public GetAdHistory(adId: string) {
        return this.GET_Request<PaginationModel<AdHistoryViewModel>>(`${this.ApiUrl}/UserAds/AdHistory/${adId}`);
    }

    public suspend(suspendAd: AdsSuspendModel) {
        return this.PUT_Request(`${this.ApiUrl}/UserAds/ChangeStatus`, suspendAd);
    }

    public delete(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/UserAds/Delete/${id}`);
    }

    public updateStatus(updateStatus: AdsSuspendModel) {
        return this.PUT_Request(`${this.ApiUrl}/UserAds/ChangeStatus`, updateStatus);
    }

    public ContactSeller(contactSeller: ContactSellerModel) {
        return this.POST_Request(`${this.ApiUrl}/UserAds/SendMailToAdOwner`, contactSeller);
    }
    public userFavoriteAdToggle(adId: string, adType: AD_PRINCIPAL_TYPE) {
        return this.GET_Request<boolean>(`${this.ApiUrl}/UserFavoriteAd/UserFavoriteAdToggle/${adId}?AdType=${adType}`);
    }
}
