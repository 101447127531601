import {PaginationModel} from '@/sdk';
import {LineChartModel, PieChartModel} from '@/sdk/models/dashboard';
import {PricePerLotTrendViewModel, UserDashboardViewModel} from '@/sdk/models/usre-dashboard';
import {BaseApi} from '../base.api';

export class DashboardApi extends BaseApi {
    public GetPieChart() {
        return this.GET_Request<UserDashboardViewModel>(`${this.ApiUrl}/Dashboard/GetTopPieChart`);
    }

    public GetGraphData(months: number) {
        return this.GET_Request<Array<PricePerLotTrendViewModel>>(`${this.ApiUrl}/Dashboard/GetGraphData/${months}`);
    }
}
