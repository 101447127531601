import {AddSupportTicketModel, ListSupportTicketModel, PaginationModel, UpdateSupportTicketModel, UpdateSupportTicketStatusModel} from '@/sdk';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BaseApi} from '../base.api';

export class SupportTicketApi extends BaseApi {
    public getAll(filter?: FilterModel, InActive: boolean = false) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<ListSupportTicketModel>>(
            `${this.ApiUrl}/SupportTicket/GetAll?filter=${JSON.stringify(filter)}&InActive=${InActive}`
        );
    }

    public create(supportTicketData: AddSupportTicketModel) {
        return this.POST_Request(`${this.ApiUrl}/SupportTicket`, supportTicketData);
    }
    public reportAbuse(supportTicketData: AddSupportTicketModel) {
        return this.POST_Request(`${this.ApiUrl}/Help/AddReportAbuse`, supportTicketData);
    }

    public getById(id: string) {
        return this.GET_Request<UpdateSupportTicketModel>(`${this.ApiUrl}/SupportTicket/${id}`);
    }

    public update(supportTicketData: UpdateSupportTicketModel) {
        return this.PUT_Request(`${this.ApiUrl}/SupportTicket`, supportTicketData);
    }

    public updateStatus(updateStatus: UpdateSupportTicketStatusModel) {
        return this.PUT_Request(`${this.ApiUrl}/SupportTicket/changestatus`, updateStatus);
    }
}
