import {
    CountyModel,
    PaginationModel,
    UpdateCountyStatusModel
} from '@/sdk/models';
import { FilterModel } from '@/sdk/models/common/filter.model';
import { BaseApi } from '../base.api';

export class CountyApi extends BaseApi {
    public getCountyByState(id?: string, filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<CountyModel>>(
            `${this.ApiUrl}/County?filter=${JSON.stringify(
                filter
            )}&stateId=${id}`
        );
    }

    public getById(id: string) {
        return this.GET_Request<CountyModel>(`${this.ApiUrl}/County/${id}`);
    }

    public update(countyData: CountyModel) {
        return this.PUT_Request(`${this.ApiUrl}/County`, countyData);
    }

    public updateStatus(updateStatus: UpdateCountyStatusModel) {
        return this.PUT_Request(
            `${this.ApiUrl}/County/changestatus`,
            updateStatus
        );
    }
}
