import { ServiceClass } from '@/decorators';
import { CityApi } from '@/sdk/api-services';
import { QuickLinksApi } from '@/sdk/api-services/quick-links/quick-links.api';
import { CityModel } from '@/sdk/models';
import { FilterModel } from '@/sdk/models/common/filter.model';
import { QuickLinksModel } from '@/sdk/models/quick-links/quick-links.model';
import { BehaviorSubject } from 'rxjs';
import { DataOptions } from 'vuetify';
import { LoaderService } from '../shared/loader.service';

@ServiceClass()
export class CityService {
    public CityData = new BehaviorSubject<Array<CityModel>>([]);
    public Filter = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length
            ? event.sortDesc[0]
            : false;
    };

    public getAll() {
        new LoaderService().showFullScreenLoader();
        new CityApi()
            .getAll()
            .subscribe(({ Data }) => {
                if (Data) {
                    this.CityData.next(Data.Data ?? []);
                    this.TotalRows = Data?.TotalRows || 0;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
