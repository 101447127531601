import {ServiceClass} from '@/decorators';
import {MembershipApi, MembershipViewModel} from '@/sdk';

@ServiceClass()
export class MembershipService {
    public membershipApi: MembershipApi = new MembershipApi();

    public activeMemberships: Array<MembershipViewModel> = [];

    constructor() {}

    public GetActiveMemberships() {
        return new Promise((resolve, reject) => {
            this.membershipApi.GetActiveMemberships().subscribe(
                res => {
                    this.activeMemberships = res.Data?.filter(x => x.Slug !== 'cancelled-membership') ?? [];
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }
}
