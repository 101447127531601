import {UserMembershipViewModel} from '..';

export class UsersModel {
    public Id?: string | null = null;
    public FirstName: string | null = null;
    public LastName: string | null = null;
    public PhoneNumber: string | null = null;
    public AltPhoneNumber: string | null = null;
    public Bio: string | null = null;
    public Email: string | null = null;
    public Address1: string | null = null;
    public Address2: string | null = null;
    public City: string | null = null;
    public CountyId: string | null = null;
    public StateId: string | null = null;
    public Zip: string | null = null;
    public TimeZone: string | null = null;
    public MemberType: string = 'Admin';
    // public RegistrationDate: string | null = null;
    public Image: string | null = null;
    public ImageUrl: string | null = null;
    public Status: boolean | null = false;

    public UserMemberships: Array<UserMembershipViewModel> | null = null;

    public IsPublic: boolean = false;

    constructor(data?: Partial<UsersModel>) {
        Object.assign(this, data);
    }
}
export class StatusModel {
    public Id?: string | null = null;
    public status: boolean | null = null;

    constructor(data?: Partial<StatusModel>) {
        Object.assign(this, data);
    }
}
export class ChangeUserPasswordModel {
    public Id?: string | null = null;
    public NewPassword?: string | null = null;
    public ConfirmPassword?: string | null = null;

    constructor(data?: Partial<ChangeUserPasswordModel>) {
        Object.assign(this, data);
    }
}
export class RoleUsersModel {
    public Id?: string | null = null;
    public Name: string | null = null;

    constructor(data?: Partial<RoleUsersModel>) {
        Object.assign(this, data);
    }
}

export enum Roles {
    Admin = 'Admin',
    User = 'User'
}
