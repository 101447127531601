import {ListNotificationModel, NotificationModel, PaginationModel, UpdateNotificationModel} from '@/sdk';
import {CountModel} from '@/sdk/models/common/count.model';
import {FilterModel} from '@/sdk/models/common/filter.model';

import {BaseApi} from '../base.api';

export class NotificationApi extends BaseApi {
    public getAll(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<ListNotificationModel>>(`${this.ApiUrl}/Notification/GetAll?filter=${JSON.stringify(filter)}`);
    }

    public getById(id: string) {
        return this.GET_Request<NotificationModel>(`${this.ApiUrl}/Notification/${id}`);
    }
    public getCount(catId: string) {
        return this.GET_Request<number>(`${this.ApiUrl}/Notification/CatNotificationCount/${catId}`);
    }
    public DisableCategoryNotification(catId: string) {
        return this.GET_Request(`${this.ApiUrl}/Notification/DisableCatNotification/${catId}`);
    }
    public getUserNotifications(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<CountModel<ListNotificationModel>>(`${this.ApiUrl}/Notification/UserNotifications?filter=${JSON.stringify(filter)}`);
    }

    public create(notificationData: NotificationModel) {
        return this.POST_Request(`${this.ApiUrl}/Notification`, notificationData);
    }

    public update(notificationData: NotificationModel) {
        return this.PUT_Request(`${this.ApiUrl}/Notification`, notificationData);
    }

    public delete(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/Notification/${id}`);
    }

    public updateStatus(updateStatus: UpdateNotificationModel) {
        return this.PUT_Request(`${this.ApiUrl}/Notification/changestatus`, updateStatus);
    }
}
