export class CompanyModel {
    public Id?: string | null = null;
    public Title: string | null = null;
    public Information: string | null = null;
    public Address: string | null = null;
    public Address2: string | null = null;
    public City: string | null = null;
    public Zip: string | null = null;
    public Logo: string | null = null;
    public PhoneNumber: string | null = null;
    public TimeZone: string | null = null;
    public Website: string | null = null;
    public UserRole: string | null = null;
    public StateId: string | null = null;

    constructor(data?: CompanyModel) {
        Object.assign(this, data);
    }
}
