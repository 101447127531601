export class LookupViewModel {
    public Id?: string | null = null;
    public Title: string | null = null;
    public Type: string | null = null;
    public SubType: string | null = null;
    public Status: boolean | null = false;

    constructor(data?: Partial<LookupViewModel>) {
        Object.assign(this, data);
    }
}

export class ListLookupViewModel {
    public Id: string | null = null;
    public Title: string | null = null;
    public UserName: string | null = null;
    public Created: string | null = null;
    public ActiveStatus: string | null = null;

    constructor(data?: Partial<ListLookupViewModel>) {
        Object.assign(this, data);
    }
}

export class UpdateLookupStatusModel {
    public Id: string | null = null;
    public Status: string | null = null;

    constructor(data?: Partial<UpdateLookupStatusModel>) {
        Object.assign(this, data);
    }
}

export enum LookupType {
    CATEGORIES_SETTING = 'CATEGORIES_SETTING',
    USER_ADS = 'USER_ADS',
    USER_NOTIFICATION = 'USER_NOTIFICATION',
    KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
    TICKET_CATEGORIES = 'TICKET_CATEGORIES',
    SUPPORT_TICKET = 'SUPPORT_TICKET',
    REPORT_ABUSE = 'REPORT_ABUSE'
}
