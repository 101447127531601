import {ListLookupViewModel, LookupType, LookupViewModel, UpdateLookupStatusModel, PaginationModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BaseApi} from '../base.api';

export class LookupApi extends BaseApi {
    public getLookups(type: LookupType, filter?: FilterModel, subType?: string) {
        return this.GET_Request<PaginationModel<ListLookupViewModel>>(
            `${this.ApiUrl}/Lookup/GetAll?type=${type}&subType=${!!subType ? subType : ''}&filter=${JSON.stringify(filter)}`
        );
    }

    public getById(id: string) {
        return this.GET_Request<LookupViewModel>(`${this.ApiUrl}/Lookup/${id}`);
    }

    public create(lookup: LookupViewModel) {
        return this.POST_Request(`${this.ApiUrl}/Lookup`, lookup);
    }

    public update(lookup: LookupViewModel) {
        return this.PUT_Request(`${this.ApiUrl}/Lookup`, lookup);
    }

    public deleteById(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/Lookup/${id}`);
    }

    public updateStatus(updateStatus: UpdateLookupStatusModel) {
        return this.PUT_Request(`${this.ApiUrl}/Lookup/changestatus`, updateStatus);
    }
}
