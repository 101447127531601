import { render, staticRenderFns } from "./explore-card.vue?vue&type=template&id=4492b6eb&scoped=true&"
import script from "./explore-card.ts?vue&type=script&lang=ts&"
export * from "./explore-card.ts?vue&type=script&lang=ts&"
import style0 from "./explore-card.vue?vue&type=style&index=0&id=4492b6eb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4492b6eb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseCard: require('C:/agent/_work/4/s/components/vuetify/base-card/base-card.vue').default})
