import {BrandingModel} from '../park-listing';

export class ClassifiedAdModel {
    public AdId: string | null = null;
    public Id?: string | null = null;
    public AdStatus: string | null = 'SAVED';
    public ActiveStatus: string | null = null;
    public AdType: string | null = 'BASIC';
    public LookupId: string | null = null;
    public Title: string | null = null;
    public SecondaryTitle: string | null = null;
    public LocationOffered: string | null = null;
    public StateId: string | null = null;
    public Description: string | null = null;
    public AskingPrice: number | null = null;
    public Images: Array<File> | null = null;
    public ImagesViewModel: Array<ClassifiedAdImageModel> | null = null;
    public PublishedDate: string | null = null;
    public ImagesToRemove: Array<string> | null = null;
    public UniqueId: string | null = null;
    public BrandingCompanyLogo: File | null = null;
    public BrandingProfilePicture: File | null = null;
    public BrandingData?: BrandingModel | null = null;
    public BrandingString: BrandingModel | null = null;
    public AdUrl: string | null = 'SAVED';
    public IsFavorite: boolean | null = null; 
    public Thumbnail: string | null = null;

    public RemoveBrandingLogo: boolean | null = null;
    public RemoveBrandingProfilePicture: boolean | null = null;

    constructor(data?: ClassifiedAdModel) {
        Object.assign(this, data);
    }
}
export class ClassifiedAdImageModel {
    public ImageId?: string | null = null;
    public ImageUrl: string | null = null;

    constructor(data?: ClassifiedAdImageModel) {
        Object.assign(this, data);
    }
}
