import {ServiceClass} from '@/decorators';
import {LookupApi} from '@/sdk/api-services';
import {ListLookupViewModel, LookupType, LookupViewModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

// type Lookup = Record<
//     keyof typeof LookupType,
//     BehaviorSubject<Array<ListLookupViewModel>>
// >;

@ServiceClass()
export class LookupService {
    public CATEGORIES_SETTING = new BehaviorSubject<Array<ListLookupViewModel>>([]);
    public USER_ADS = new BehaviorSubject<Array<ListLookupViewModel>>([]);
    public USER_NOTIFICATION = new BehaviorSubject<Array<ListLookupViewModel>>([]);
    public KNOWLEDGE_BASE = new BehaviorSubject<Array<ListLookupViewModel>>([]);
    public TICKET_CATEGORIES = new BehaviorSubject<Array<ListLookupViewModel>>([]);
    public REPORT_ABUSE = new BehaviorSubject<Array<ListLookupViewModel>>([]);
    public SUPPORT_TICKET = new BehaviorSubject<Array<ListLookupViewModel>>([]);

    public LookupById = new BehaviorSubject(new LookupViewModel());
    public Filter = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
    };

    public getLookups(type: LookupType, subType?: string, all = false) {
        if (all) {
            this.Filter.PageNo = 0;
        }

        return new Promise(resolve => {
            new LoaderService().showFullScreenLoader();
            new LookupApi()
                .getLookups(type, this.Filter, subType)
                .subscribe(({Data}) => {
                    if (Data) {
                        this[type].next(Data.Data);
                        this.TotalRows = Data?.TotalRows || 0;
                    }
                })
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                    resolve(null);
                });
        });
    }

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();

        new LookupApi()
            .getById(id)
            .subscribe(({Data}) => {
                if (Data) {
                    this.LookupById.next(Data);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
