import {Component, Prop} from 'vue-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import {AnyObject} from '~/globals';

@Component
export default class AdvertiseCardComponent extends VueWrapper {
    @Prop({
        type: Object,
        required: true,
    })
    item!: {title: string; img: string; link: string; alt?: string,description: string};
}
