export class ListingDataModel {
    public Id?: string | null = null;
    public ListingTitle: string | null = null;
    public SecondaryTitle: string | null = null;
    public MLSNo: string | null = null;
    public ExternalListingPageLink: string | null = null;
    public AskingPrice: number | null = null;
    public OffMarket: boolean | null = null;
    public UnPriced: boolean | null = null;
    public EarnestDeposit: number | null = null;
    public PropertyTaxes: number | null = null;
    public PropertyTaxYear: number | null = null;
    public PricePerLot: number | null = null;
    public Status: string | null = 'true';
    public NOI: string | null = null;
    public CAPRate: number | null = null;
    public SellerFinancing: boolean | null = false;
    public Cash: boolean | null = false;
    public NewLoan: boolean | null = false;
    public LoanAssumption: boolean | null = false;
    public PropertyDescription: string | null = null;
    public TouringAndDrivingDirection: string | null = null;
    public ParkId: string | null = null;
    public ParkName: string | null = null;
    public ImagesToDelete: Array<string> | null = null;
    public MediaFiles: Array<{Id: string | null; Url: string; Sequence: number; Title: string}> | null = null;
    public ListingStatus: string | null = null;
    public ListingAdStatus: string | null = null;
    public YouTubeLink: string | null = null;

    constructor(data?: Partial<ListingDataModel>) {
        Object.assign(this, data);
    }
}
