export class StateModel {
    public Id?: string | null = null;
    public Code: string | null = null;
    public Name: string | null = null;
    public Website: string | null = null;
    public ActiveStatus: boolean | null = false;

    constructor(data?: StateModel) {
        Object.assign(this, data);
    }
}

export class UpdateStateStatusModel {
    public Id: string | null = null;
    public Status: string | null = null;
    constructor(data?: UpdateStateStatusModel) {
        Object.assign(this, data);
    }
}
