import {AdvertiseParkPermissionViewModel} from '../advertise-park-permission/advertise-park-permission-view.model';

export class UserParkSiteInfoModel {
    public LastUpdateBy: string | null = null;
    public LastUpdate: string | null = null;
    public Latitude: number | null = null;
    public Longitude: number | null = null;
    public ParkNo: number | null = null;
    public ParkNoPredefined: number | null = null;
    public ParkName: string | null = null;
    public StreetAddress: string | null = null;
    public County: string | null = null;
    public City: string | null = null;
    public Phone: string | null = null;
    public TimeZone: string | null = null;
    public Type: string | null = null;
    public Status: string | null = null;
    public Popullation: number | null = null;
    public HouseHolds: number | null = null;
    public Income: number | null = null;
    public LandArea: number | null = null;
    public WaterArea: number | null = null;
    public CurrentlyClaimedBy: string | null = null;
    public ClaimedByMe: boolean | null = null;
    public TotalHomeLots: string | null = null;
    public TotalRVLots: string | null = null;
    public State: string | null = null;
    public Country: string | null = null;
    public ParkId: string | null = null;
    public UniqueId: string | null = null;
    public ZipCode: string | null = null;
    public Contacted: boolean | null = null;

    public AdvertiseParkPermission: AdvertiseParkPermissionViewModel | null = null;
    public IsAlreadyPublished: boolean | null = null;

    constructor(data?: Partial<UserParkSiteInfoModel>) {
        Object.assign(this, data);
    }
}
