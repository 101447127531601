export class CityModel {
    public Id: string | null = null;
    public SrNo: number | null = null;
    public Name: string | null = null;
    public County: string | null = null;
    public StateCode: string | null = null;
    public State: string | null = null;
    public ZipCode: string | null = null;
    public Type: string | null = null;
    public Latitude: string | null = null;
    public Longitude: string | null = null;
    public AreaCode: string | null = null;
    public Population: string | null = null;
    public HouseHold: string | null = null;
    public MedianIncome: string | null = null;
    public LandArea: string | null = null;
    public WaterArea: string | null = null;
    public TimeZone: string | null = null;

    constructor(data?: Partial<CityModel>) {
        Object.assign(this, data);
    }
}
