import {Component, Vue} from 'nuxt-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import ClientAppBarComponent from '~/layouts/client-layout/client-app-bar/client-app-bar.component';
import ClientDrawerComponent from '~/layouts/client-layout/client-drawer/client-drawer.component';
import ClientFooterComponent from '~/layouts/client-layout/client-footer/client-footer.component';
import {LoaderService} from '~/sdk';
import {LibrariesService} from '~/sdk/services/libraries.service';
// import {LibrariesService} from '~/sdk/services/libraries.service';
@Component<MainLayoutComponent>({
    components: {
        ClientDrawerComponent,
        ClientFooterComponent,
        ClientAppBarComponent,
    },
    head() {
        // console.log(this.$route, process);
        return {
            link: [
                {
                    rel: 'canonical',
                    href: 'https://' + this.host + this.$route.fullPath,
                },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter&family=Nunito+Sans:wght@200;400&family=Open+Sans&family=Urbanist&display=swap',
                },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans&family=Urbanist&display=swap',
                },
            ],
        };
    },
})
export default class MainLayoutComponent extends Vue {
    public host: string = '';
    public async fetch() {
        if (this.$ssrContext?.req?.headers?.host) {
            this.host = this.$ssrContext?.req?.headers?.host;
        }
    }
    public LoaderSrv: LoaderService = new LoaderService();
    // public callingMethod() {
    //     (this as any)
    //         .$loadScript('/js/feedback.js')
    //         .then(() => {
    //             alert('okok, done');
    //         })
    //         .catch(() => {
    //             alert('okok, F');
    //             // Failed to fetch script
    //         });
    // }
    ml_webform_4646668() {
        (window as any).ml_webform_4646668('show');
    }
    public mounted() {
        new LoaderService().showFullScreenLoader();
        this.LoaderSrv = new LoaderService();
        new LibrariesService().loadGoogleMapsLibrary();
        // window.EmbedSocialFeedbackButton()
        setTimeout(() => {
            new LoaderService().hideFullScreenLoader();
        }, 2000);
    }
    public beforeDestroy(): void {}
}
