
import ViewCardsComponent from '~/components/main-layout-new-design/pricing/view-cards/view-card.component';
export default {
    name: 'EmptyLayout',
    layout: 'main-layout.component',
    components: {
        ViewCardsComponent,
    },
    data() {
        return {
            scriptSchema: [
                {
                    type: 'application/ld+json',
                    json: {
                        '@context': 'http://schema.org',
                        '@type': 'WebPage',
                        name: 'Aw Snap! Error | MHParks.com',
                    },
                },
            ],
            parksAvailabilityNo: [
                {title: '26k', icon: 'mobile-home-park-lots.svg', text: 'Parks that contain only mobile home lots', alt: 'Mobile Home Park Lots Only'},
                {title: '2k', icon: 'rv-park-lots.svg', text: 'Parks that contain only RV lots', alt: 'RV Park Lots Only'},
                {
                    title: '2k',
                    icon: 'mobile-home-park-rv-park-lots.svg',
                    text: 'Parks that contain both MH/RV lots',
                    alt: 'Both Mobile Home Park and RV Park Lots',
                },
                {title: '15k', icon: 'mobile-home-park-streets.svg', text: 'Parks that contain paved streets', alt: 'Mobile Home Park Paved Streets'},
            ],
            advertiseData: [
                {
                    title: 'Park For Sale Ads',
                    description: 'View MH/RV Park for Sale Ads that our members have published while using our Advertise solution',
                    img: '/images/new-layout/pricing-images/handSpeaker.png',
                    link: 'park-for-sale-ads',
                    alt: 'Park For Sale Ads',
                },
                {
                    title: 'Classified Ads',
                    description: 'View MH/RV Park Classified Ads published by our members while using our Advertise solution',
                    img: '/images/new-layout/pricing-images/globeSearch.png',
                    link: 'classified-ads',
                    alt: 'Classified Ads',
                },
                {
                    title: 'Professionals',
                    description: 'View Professionals serving the MH/RV park industry that are using our Professionals solution',
                    img: '/images/new-layout/pricing-images/securityUser.png',
                    link: 'professionals',
                    alt: 'professionals',
                },
            ],
            data: [
                {
                    title: 'Our Solutions',
                    img: '/images/new-layout/solution-icon.png',
                    link: 'solutions',
                    alt: 'Our Solutions',
                },
                {
                    title: 'Park For Sale Ads',
                    img: '/images/new-layout/speaker-icon.png',
                    link: 'park-for-sale-ads',
                    alt: 'Park For Sale Ads',
                },
                {
                    title: 'Classified Ads',
                    img: '/images/new-layout/search-icon.png',
                    link: 'classified-ads',
                    alt: 'Classified Ads',
                },
            ],
            items: [
                {
                    title: 'Our Solutions',
                    img: 'images/new-layout/solution-icon.png',
                    description: 'View the solutions that help teach Pax about parks',
                },
                {
                    title: 'Park For Sale Ads',
                    img: 'images/new-layout/speaker-icon-64.png',
                    description: 'View the solutions that help teach Pax about parks',
                },
                {
                    title: 'Classified Ads',
                    img: 'images/new-layout/search-icon-64.png',
                    description: 'View Park for Sale Ads that members have published',
                },
                {
                    title: 'Professionals',
                    img: 'images/new-layout/user-icon-64.png',
                    description: 'View Classified Ads published by our members',
                },
            ],
            Solutions: [
                {
                    title: 'Park Details',
                    desc: 'View or edit the physical attributes of each MH/RV park! Leveraging our unique design, the physical attributes displayed within Park Details are able to remain accurate over time unlike traditional sites or lists.',
                },
                {
                    title: 'Parcel Data',
                    desc: 'Access each MH/RV park’s Parcel Data with a simple click! Using the built-in Parcel Data button enables you to view over 50 assessor recorded attributes such as the Parcel ID, Owner, and Owner Address.',
                },
                {
                    title: 'Advanced Filter',
                    desc: 'Target your hunt for MH/RV parks with precision! Our Filter button allows you to filter our MH/RV park records using over 47 data points combined with an unlimited number of AND/OR conditions.',
                },
                {
                    title: 'Customized Favorites List',
                    desc: 'Easily track MH/RV parks the way that works best for you! Customize your Favorites Types to quickly identify and work with specific mobile home and RV park records using your Favorites List. ',
                },
                {
                    title: 'Pax Reports',
                    desc: 'Gain statistical knowledge about mobile home parks and RV parks that you’ve targeted! Simply apply your filter options to the map and click the Pax Reports button to open the available reports.',
                },
                {
                    title: 'Websites Option',
                    desc: 'Save wasted time Googling a MH/RV park’s state or county website by using our Websites button. This allows you to quickly deep dive into a MH/RV park’s deed or zoning information directly at the source.',
                },
                {
                    title: 'Park History',
                    desc: 'View our system’s history for each MH/RV park record with a simple click of a button! This allows you to gain insight about when a MH/RV park was edited, advertised, sold, or even claimed in our system.',
                },
                {
                    title: 'Contacts CRM Integrated',
                    desc: 'Use the Contacts CRM button within Park Sites to manage your private contacts that are specific to each MH/RV park. Our unique integration ensures your private contacts and leads remain private to you.',
                },
                {
                    title: 'My Parks Integrated',
                    desc: 'Bring off-market offers without having to advertise! Simply claim each MH/RV park you own by using our My Parks solution. After that we’ll add your branding to the park’s details page within Park Sites. ',
                },
            ],
            src: '/images/new-layout/not-found.png',
        };
    },
    head() {
        return {
            title: 'Aw Snap! Error',
            meta: [{hid: 'robots', name: 'robots', content: 'noindex, nofollow'}],

            script: [...this.scriptSchema],
        };
    },
    // head() {
    //   const title =
    //     this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    //   return {
    //     title,
    //   }
    // },
};
