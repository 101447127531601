import { render, staticRenderFns } from "./signup-card.vue?vue&type=template&id=2108f8af&scoped=true&"
import script from "./signup-card.ts?vue&type=script&lang=ts&"
export * from "./signup-card.ts?vue&type=script&lang=ts&"
import style0 from "./signup-card.vue?vue&type=style&index=0&id=2108f8af&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2108f8af",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('C:/agent/_work/4/s/components/vuetify/base-btn/base-btn.vue').default})
