import {CompanyModel} from '../company/company.model';

export class PublicProfileModel {
    public Id: string | null = null;
    public Name: string | null = null;
    public ImageURL: string | null = null;
    public RoleInIndustry: string | null = null;
    public Email: string | null = null;
    public Phone: string | null = null;
    public Bio: string | null = null;
    public CompanyImageUrl: string | null = null;
    public IsPofessionalPublished: boolean | null = null;

    constructor(data?: Partial<PublicProfileModel>) {
        Object.assign(this, data);
    }
}

export class ProfessionalProfileModel {
    public Id: string | null = null;
    public FirstName: string | null = null;
    public LastName: string | null = null;
    public Email: string | null = null;
    public Phone: string | null = null;
    public AltPhone: string | null = null;
    public Facebook: string | null = null;
    public Twitter: string | null = null;
    public LinkedIn: string | null = null;
    public Youtube: string | null = null;
    public Description: string | null = null;
    public Status: string | null = null;
    public ImageUrl: string | null = null;
    public PrimaryRole: string | null = null;
    public Views: number | null = null;
    public Company: CompanyModel | null = null;

    constructor(data?: Partial<ProfessionalProfileModel>) {
        Object.assign(this, data);
    }
}
