import {UsersModel} from '@/sdk';

export class BlogModel {
    public Id: string | null = null;
    public Title: string | null = null;
    public Content: string | null = null;
    public Status: string | null = 'Draft';
    public Author?: UsersModel | null = null;
    public PublishedDate: string | null = null;
    public AuthorId: string | null = null;
    public AuthorName: string | null = null;
    public Slug: string | null = null;
    public PrimaryKeyword: string | null = null;
    public SecondaryKeywords: Array<string> | null = [];
    public SeoPageTitle: string | null = null;
    public SeoMetaDescription: string | null = null;
    public Active: boolean | null = false;
    public Category: string | null = null;
    public Solutions: Array<string> | null = [];
    public Created: string | null = null;
    // public Images: Array<File> | null = null;

    public FeaturedImageToUpload: File | null = null;
    public AuthorImageToUpload: File | null = null;

    public BlogImages: Array<BlogImagesModel> | null = null;
    public ImagesToRemove: Array<string> | null = null;

    constructor(data?: Partial<BlogModel>) {
        Object.assign(this, data);
    }
}
export class BlogListingViewModel {
    public Id: string | null = null;
    public Title: string | null = null;
    public Content: string | null = null;
    public Status: string | null = null;
    public PublishedDate: string | null = null;
    public AuthorId: string | null = null;
    public Author?: UsersModel | null = null;
    public Slug: string | null = null;
    public PrimaryKeyword: string | null = null;
    public SecondaryKeywords: Array<string> | null = null;
    public SeoPageTitle: string | null = null;
    public SeoMetaDescription: string | null = null;
    public Active: boolean | null = false;
    public Created: string | null = null;
    public BlogImages: Array<BlogImagesModel> | null = null;

    constructor(data?: Partial<BlogModel>) {
        Object.assign(this, data);
    }
}

export class BlogImagesModel {
    public Id: string | null = null;
    public Url: string | null = null;
    public PrincipleType: number | null = null;
    constructor(data?: Partial<BlogImagesModel>) {
        Object.assign(this, data);
    }
}

export class BlogStatusModel {
    public Id: string | null = null;
    public Status: string | null = null;

    constructor(data?: Partial<BlogStatusModel>) {
        Object.assign(this, data);
    }
}
