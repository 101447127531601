export class ParksInAreaSpViewModel {
    public I: string | null = null;
    public P: string | null = null;
    public L: number | null = null;
    public N: number | null = null;
    public C: boolean | null = null;
    public S: string | null = null;

    public ClaimedStatus: boolean | null = null;
    public IsClaimedByMe: boolean | null = null;
    public Active: boolean | null = null;
    public Status: string | null = null;

    constructor(data?: ParksInAreaSpViewModel) {
        if (data) {
            if ((data.S?.length || 0) > 0) {
                data.Status = data.S!.split('|')[0];
                data.Active = data.S?.endsWith('|1') ?? false;
                this.IsClaimedByMe = data.S?.includes('|Y|') ?? false;
                this.ClaimedStatus = data.S?.includes('|C|') ?? false;
            }
        }
        Object.assign(this, data);
    }

    public get pinType() {
        if (!this.Active) {
            return 'InActive';
        }
        return this.Status;
    }

    // public get pinTypeForMyParks() {
    //     return this.ClaimedStatus ? 'Claimed' : 'UnClaimed';
    // }

    public get pinTypeUser() {
        if (this.C) {
            return 'Contacted';
        }
        if (this.IsClaimedByMe) {
            return 'ClaimedByMe';
        }
        if (this.Status == 'Claimed' || this.Status === 'UnClaimed') {
            return this.ClaimedStatus ? 'Claimed' : 'UnClaimed';
        }
        return this.Status;
    }
    public get myParksPinType() {
        if (this.IsClaimedByMe) {
            return 'ClaimedByMe';
        }
        if (this.Status == 'Claimed' || this.Status === 'UnClaimed') {
            return this.ClaimedStatus ? 'Claimed' : 'UnClaimed';
        }
        return this.Status;
    }
}
