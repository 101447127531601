import {AttachPaymentMethodViewModel, ChangePasswordModel, PaginationModel, PaymentInformationViewModel, StripeCustomerChargeModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {ChangeUserPasswordModel, StatusModel, UsersModel, RoleUsersModel} from '@/sdk/models/users/users.model';
import {BaseApi} from '../base.api';

export class UserPaymentsApi extends BaseApi {
    public PaymentMethods(id: string) {
        return this.GET_Request<Array<PaymentInformationViewModel>>(`${this.ApiUrl}/UserPayment/PaymentMethods/${id}`);
    }

    public AttachPaymentMethodtoCustomer(data: AttachPaymentMethodViewModel) {
        return this.POST_Request<AttachPaymentMethodViewModel>(`${this.ApiUrl}/UserPayment/AttachPaymentMethod`, data);
    }

    public GetUserChargeHistory(id: string) {
        return this.GET_Request<PaginationModel<StripeCustomerChargeModel>>(`${this.ApiUrl}/UserPayment/GetUserChargeHistory/${id}`);
    }
}
