import { ServiceClass } from '@/decorators';
import { CompanyApi } from '@/sdk';
import { CompanyModel } from '@/sdk/models';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from '../shared/loader.service';

@ServiceClass()
export class CompanyService {
    public Company = new BehaviorSubject(new CompanyModel());

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();

        new CompanyApi()
            .getById(id)
            .subscribe(({ Data }) => {
                if (Data) {
                    this.Company.next(Data);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
