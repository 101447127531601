export class DynamicPagesInfoModel {
    public ParkInformation: ParkInformation | null = null;
    public OwnerInformation: OwnerInformation | null = null;

    constructor(data?: Partial<DynamicPagesInfoModel>) {
        Object.assign(this, data);
    }
}

export class ParkInformation {
    public Phone: string | null = null;
    public Address: string | null = null;
    public ParkName: string | null = null;
    public TotalMhLots: string | null = null;
    public TotalRvLots: string | null = null;
    public State: string | null = null;

    constructor(data?: Partial<ParkInformation>) {
        Object.assign(this, data);
    }
}
export class OwnerInformation {
    public Name: string | null = null;
    public Address: string | null = null;
    public Phone: string | null = null;

    constructor(data?: Partial<OwnerInformation>) {
        Object.assign(this, data);
    }
}
