import {KnowledgeBaseModel, ListKnowledgebaseViewModel, PaginationModel, UpdateKnowledgebaseViewModel} from '@/sdk/models';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BaseApi} from '../base.api';

export class KnowledgeBaseApi extends BaseApi {
    public getAll(filter?: FilterModel, categoryId?: string, tabName?: string) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<ListKnowledgebaseViewModel>>(
            `${this.ApiUrl}/Knowledgebase/GetAll?filter=${JSON.stringify(filter)}${!!tabName ? `&tabName=${tabName}` : ''}${
                !!categoryId ? `&categoryId=${categoryId}` : ''
            }`
        );
    }

    public create(knowledgebaseData: KnowledgeBaseModel) {
        return this.POST_Request(`${this.ApiUrl}/Knowledgebase`, knowledgebaseData);
    }
    public getById(id: string) {
        return this.GET_Request<KnowledgeBaseModel>(`${this.ApiUrl}/Knowledgebase/${id}`);
    }

    public update(knowledgebaseData: KnowledgeBaseModel) {
        return this.PUT_Request(`${this.ApiUrl}/Knowledgebase`, knowledgebaseData);
    }

    public delete(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/Knowledgebase/${id}`);
    }

    public updateStatus(updateStatus: UpdateKnowledgebaseViewModel) {
        return this.PUT_Request(`${this.ApiUrl}/Knowledgebase/changestatus`, updateStatus);
    }
}
