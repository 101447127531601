import {ClassifiedAdModel, ListingViewsViewModel, MediaFileModel, PaginationModel, PublishAdViewModel, ShareClassifiedAdViewModel} from '@/sdk/models';
import {FilterModel, ListingFilterViewModel} from '@/sdk/models/common/filter.model';
import {BaseApi} from '../base.api';

export class ClassifiedApi extends BaseApi {
    // public getAll(filter?: FilterModel) {
    //     if (!filter) {
    //         filter = new FilterModel();
    //     }
    //     return this.GET_Request<PaginationModel<ListKnowledgebaseViewModel>>(
    //         `${this.ApiUrl}/Knowledgebase/GetAll?filter=${JSON.stringify(
    //             filter
    //         )}`
    //     );
    // }

    public ClassifiedUploads(UserId: string, files: Array<File>) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`files[${i}]`, files[i]);
        }

        return this.POST_FileRequest<Array<MediaFileModel>>(`${this.ApiUrl}/Mediafile/ClasasifiedUploads/${UserId}`, formData);
    }

    public create(classifiedAdData: ClassifiedAdModel) {
        const data = new FormData();

        data.append('AdStatus', classifiedAdData.AdStatus!);
        data.append('AdType', classifiedAdData.AdType!);
        data.append('LookupId', classifiedAdData.LookupId!);
        data.append('Title', classifiedAdData.Title!);
        data.append('SecondaryTitle', classifiedAdData.SecondaryTitle!);
        data.append('LocationOffered', classifiedAdData.LocationOffered!);
        data.append('StateId', classifiedAdData.StateId!);
        data.append('Description', classifiedAdData.Description!);
        data.append('AskingPrice', JSON.parse(JSON.stringify(classifiedAdData.AskingPrice!)));
        data.append('BrandingString', JSON.stringify(classifiedAdData.BrandingData));
        data.append('BrandingData', JSON.stringify(classifiedAdData.BrandingData));

        if (classifiedAdData.RemoveBrandingLogo) {
            data.append('RemoveBrandingLogo', `${classifiedAdData.RemoveBrandingLogo}`);
        }
        if (classifiedAdData.RemoveBrandingProfilePicture) {
            data.append('RemoveBrandingProfilePicture', `${classifiedAdData.RemoveBrandingProfilePicture}`);
        }

        data.append('BrandingCompanyLogo', classifiedAdData.BrandingCompanyLogo!);
        data.append('BrandingProfilePicture', classifiedAdData.BrandingProfilePicture!);
        if (classifiedAdData.PublishedDate) {
            data.append('PublishedDate', classifiedAdData.PublishedDate!);
        }
        if (classifiedAdData.Images) {
            for (let i = 0; i < classifiedAdData.Images.length; i++) {
                const element = classifiedAdData.Images[i];
                data.append(`Images`, element);
            }
        }
        if (classifiedAdData.Id) {
            data.append('Id', classifiedAdData.Id);
        }
        data.append('ImagesViewModel', JSON.stringify(classifiedAdData.ImagesViewModel!));
        if (classifiedAdData.ImagesToRemove) {
            for (const Id of classifiedAdData.ImagesToRemove) {
                data.append('ImagesToRemove', Id);
            }
        }

        return this.POST_FileRequest<ClassifiedAdModel>(`${this.ApiUrl}/UserAds`, data);
    }

    public GetUserClassifiedAds(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }

        return this.GET_Request<PaginationModel<ClassifiedAdModel>>(`${this.ApiUrl}/UserAds/GetUserClassifiedAds?filter=${JSON.stringify(filter)}`);
    }

    public GetAdViews(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel({
                PageSize: -1,
            });
        }

        return this.GET_Request<PaginationModel<ListingViewsViewModel>>(`${this.ApiUrl}/UserAds/GetAdViews?filter=${JSON.stringify(filter)}`);
    }

    public GetUserClassifiedAdById(id: string) {
        return this.GET_Request<ClassifiedAdModel>(`${this.ApiUrl}/UserAds/GetUserClassifiedAd/${id}`);
    }

    public ChangeStatus(id: string, status: string) {
        return this.GET_Request<ClassifiedAdModel>(`${this.ApiUrl}/UserAds/ChangeStatus/${id}?status=${status}`);
    }

    // public PublishClassified(id: string) {
    //     return this.PUT_Request<any>(`${this.ApiUrl}/UserAds/PublishClassified/${id}`, {});
    // }

    public GetAdsByCategory(filter: FilterModel, filter1?: ListingFilterViewModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        if (!filter1) {
            filter1 = new ListingFilterViewModel();
        }

        return this.GET_Request<PaginationModel<ClassifiedAdModel>>(
            `${this.ApiUrl}/UserAds/PublicSiteAdsListing?filter=${JSON.stringify(filter)}&listingfilter=${JSON.stringify(filter1)}`
        );
    }

    public GetFeaturedClassifiedAds(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }

        return this.GET_Request<PaginationModel<ClassifiedAdModel>>(`${this.ApiUrl}/UserAds/GetFeautedClassifiedAds?filter=${JSON.stringify(filter)}`);
    }

    public Delete(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/UserAds/Delete/${id}`);
    }

    public PublishClassifiedAd(AdId: string, publishData: PublishAdViewModel) {
        return this.POST_Request<any>(`${this.ApiUrl}/UserAds/PublishAd/${AdId}`, publishData);
    }

    public SaveAdView(data: ListingViewsViewModel) {
        return this.POST_Request<any>(`${this.ApiUrl}/UserAds/SaveAdView`, data);
    }

    public ShareClassifiedAd(data: ShareClassifiedAdViewModel) {
        return this.POST_Request<any>(`${this.ApiUrl}/UserAds/ShareClassifiedAd`, data);
    }
}
