import {MediaFileModel} from '../../models';
import {BaseApi} from '../base.api';
import {map} from 'rxjs/operators';
import {ApiAuth} from '@/sdk/core';
import {Observable} from 'rxjs';
import {AxiosResponse} from 'axios';

export class MediaApi extends BaseApi {
    public getPaginatedByUserId(principalTypes: string, query: string, page: number) {
        return this.GET_Request<Array<MediaFileModel>>(
            `${this.ApiUrl}/media/${principalTypes}/get-user-media/${new ApiAuth().SessionValue?.UserId}/?query=${query ?? ''}&page=${page}`
        );
    }
    public getUserUploadedMedia() {
        return this.GET_Request<Array<MediaFileModel>>(`${this.ApiUrl}/media/get-user-uploaded-media/${new ApiAuth().SessionValue?.UserId}`);
    }
    public getAll(principalType: string, principalId: string, userId: string) {
        return this.GET_Request<Array<MediaFileModel>>(`${this.ApiUrl}/media/${principalType}/${principalId}/${userId}`);
    }
    public getOne(principalType: string, principalId: string) {
        return this.GET_Request<MediaFileModel | null>(`${this.ApiUrl}/media/${principalType}/${principalId}/get-one`);
    }
    public add(principalType: string, media: FormData) {
        return this.POST_FileRequest(`${this.ApiUrl}/media/${principalType}`, media);
    }
    public addOrUpdate(principalType: string, media: FormData) {
        return this.POST_FileRequest(`${this.ApiUrl}/media/${principalType}/add-or-update`, media);
    }
    public deleteMedia(principalType: string, principalId: string) {
        return this.DELETE_Request(`${this.ApiUrl}/media/${principalType}/${principalId}`);
    }
    public deleteById(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/media/${id}`);
    }
    public download(mediaId: string) {
        return this.downloadRequest(`${this.ApiUrl}/MediaFile/DownloadFile/${mediaId}`).pipe(
            map(Res => {
                return Res.data;
            })
        );
    }
}
