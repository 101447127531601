export class GetParkListingImagesModel {
    public Id?: string | null = null;
    public ParkImages: Array<ImagesDataModel> | null = null;
    public ListingMedia: Array<FilesDataModel> | null = null;
    public BrandingProfilePicture: ImagesDataModel | null = null;
    public BrandingCompanyLogo: ImagesDataModel | null = null;

    constructor(data?: Partial<GetParkListingImagesModel>) {
        Object.assign(this, data);
    }
}
export class ImagesDataModel {
    public Id?: string | null = null;
    public Url?: string | null = null;
    public Sequence: number | null = null;
    public FileType: string | null = null;

    constructor(data?: Partial<ImagesDataModel>) {
        Object.assign(this, data);
    }
}
export class FilesDataModel {
    public Id?: string | null = null;
    public Url: string | null = null;
    public Sequence: number | null = null;
    public FileType: string | null = null;
    public Title: string | null = null;

    constructor(data?: Partial<ImagesDataModel>) {
        Object.assign(this, data);
    }
}
