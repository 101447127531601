

export default function(to, from, savedPosition) {
      if (to.hash) {
        return { selector: to.hash };
      }
      setTimeout(() => {
        const main = document.getElementsByTagName('main');
        if (main && main[0]) {
          main[0].scrollTo(0, 0);
        }
      }, 100);
      return { x: 0, y: 0 };
    }
