import { ServiceClass } from '@/decorators';
import { QuickLinksApi } from '@/sdk/api-services/quick-links/quick-links.api';
import { FilterModel } from '@/sdk/models/common/filter.model';
import { QuickLinksModel } from '@/sdk/models/quick-links/quick-links.model';
import { BehaviorSubject } from 'rxjs';
import { DataOptions } from 'vuetify';
import { LoaderService } from '../shared/loader.service';

@ServiceClass()
export class QuickLinksService {
    public QuickLinks = new BehaviorSubject<Array<QuickLinksModel>>([]);
    public QuickLink = new BehaviorSubject(new QuickLinksModel());
    public Filter: FilterModel = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length
            ? event.sortDesc[0]
            : false;
        this.getAll();
    };

    public getAll() {
        new LoaderService().showFullScreenLoader();
        new QuickLinksApi()
            .getAll(this.Filter)
            .subscribe(({ Data }) => {
                if (Data) {
                    this.QuickLinks.next(Data.Data ?? []);
                    this.TotalRows = Data?.TotalRows || 0;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();

        new QuickLinksApi()
            .getById(id)
            .subscribe(({ Data }) => {
                if (Data) {
                    this.QuickLink.next(Data as any);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
