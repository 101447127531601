export class CountyModel {
    public Id?: string | null = null;
    public County: string | null = null;
    public Website: string | null = null;
    public GisWebsite: string | null = null;
    public StateName: string | null = null;
    public ActiveStatus: string | null = null;
    public StateCode: string | null = null;

    constructor(data?: CountyModel) {
        Object.assign(this, data);
    }
}

export class UpdateCountyStatusModel {
    public Id: string | null = null;
    public Status: string | null = null;
    constructor(data?: UpdateCountyStatusModel) {
        Object.assign(this, data);
    }
}
