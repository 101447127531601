import { render, staticRenderFns } from "./promo-card.vue?vue&type=template&id=82772df6&scoped=true&"
import script from "./promo-card.ts?vue&type=script&lang=ts&"
export * from "./promo-card.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82772df6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MhParksTextField: require('C:/agent/_work/4/s/components/base/mh-parks-text-field/mh-parks-text-field.vue').default,BaseBtn: require('C:/agent/_work/4/s/components/vuetify/base-btn/base-btn.vue').default,BaseForm: require('C:/agent/_work/4/s/components/vuetify/base-form/base-form.vue').default})
