import VueWrapper from '~/classes/vue.wrapper';
import { Component } from 'nuxt-property-decorator';

@Component
export default class ViewCardsComponent extends VueWrapper {
    public viewDetailsCards: Array<{title: string; description: string; icon: string; link: string; alt: string}> = [
        {
            title: 'Our Solutions',
            description: 'View the solutions that help teach Pax about parks',
            icon: '/images/new-layout/pricing-images/ideaBulb.png',
            link: 'solutions',
            alt: 'Our Solutions',
        },
        {
            title: 'Park For Sale Ads',
            description: 'View Park for Sale Ads that members have published',
            icon: '/images/new-layout/pricing-images/handSpeaker.png',
            link: 'park-for-sale-ads',
            alt: 'Park for Sale Ads',
        },
        {
            title: 'Classified Ads',
            description: 'View Classified Ads published by our members',
            icon: '/images/new-layout/pricing-images/globeSearch.png',
            link: 'classified-ads',
            alt: 'Classified Ads',
        },
        {
            title: 'Professionals',
            description: 'View members that opted into the Professionals List',
            icon: '/images/new-layout/pricing-images/securityUser.png',
            link: 'professionals',
            alt: 'Professionals',
        },
    ];

    public excludedRoutes = ['404-page-not-found', 'expired-ad', 'solutions-park-sites-solution', 'solutions-advertise-solution'];

    get hidePadding() {
        return this.excludedRoutes.includes(this.$route.name!);
    }
}