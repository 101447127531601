import {ServiceClass} from '@/decorators';
import {AnyObject} from '@/globals';
import {ApiResponseModel, ParksInAreaSpViewModel, UserParkSiteApi} from '@/sdk';
import {ParkSiteApi} from '@/sdk/api-services/park-site/park-site.api';
import {GetParkSiteImagesModel, ParkSiteFieldsViewModel, ParkSiteImagesModel, ParkSiteViewModel, SaveParkViewModel} from '@/sdk/models/park-site';
import {ParkFieldViewModel} from '@/sdk/models/park-site/park-field-view.model';
import {UserParkSiteInfoModel} from '@/sdk/models/park-site/user-park-site-info.model';
import {BehaviorSubject} from 'rxjs';
import {LoaderService} from '..';

@ServiceClass()
export class UserParkSiteService {
    public userParkSiteApi: UserParkSiteApi = new UserParkSiteApi();
    public parkSitesApi: ParkSiteApi = new ParkSiteApi();
    public userParkSiteInfo: UserParkSiteInfoModel = new UserParkSiteInfoModel();
    public userParksList$: BehaviorSubject<Array<ParksInAreaSpViewModel>> = new BehaviorSubject<Array<ParksInAreaSpViewModel>>([]);
    public userParkSiteImages = new ParkSiteImagesModel();
    public userParkToEdit: SaveParkViewModel = new SaveParkViewModel();

    // public parkSiteImages = new ParkSiteImagesModel();

    // public getParkSiteImages: Array<GetParkSiteImagesModel> = [];

    constructor() {}

    public resetParkSiteData() {
        this.userParkSiteInfo = new UserParkSiteInfoModel();
    }

    public get showNext() {
        return (
            !!this.userParkSiteInfo.ParkId &&
            !this.userParkSiteInfo?.IsAlreadyPublished &&
            (this.userParkSiteInfo.ClaimedByMe ||
                this.userParkSiteInfo.AdvertiseParkPermission?.Permission === 'Granted' ||
                this.userParkSiteInfo.Status === 'NoParkData' ||
                this.userParkSiteInfo.Status === 'UnClaimed')
        );
    }

    public GetParksInArea(
        swlat: number,
        swlng: number,
        nelat: number,
        nelng: number,
        status: string | null,
        stateIds: Array<string>,
        parkType: string | null = null,
        legendTypes: string | null = null
    ) {
        return new Promise((resolve: (a: ApiResponseModel<Array<ParksInAreaSpViewModel>>) => void, reject) => {
            this.parkSitesApi.GetParksInArea(swlat, swlng, nelat, nelng, status, stateIds, parkType, legendTypes).subscribe(
                async res => {
                    if (res.Data) {
                        for (let u = 0; u < res.Data.length; u++) {
                            res.Data[u] = new ParksInAreaSpViewModel(res.Data[u]);
                        }
                    }
                    // this.userParksList$.next(res.Data ?? []);
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public GetParkSiteInfo(parkId: string) {
        new LoaderService().showFullScreenLoader();
        return new Promise((resolve: (r: ApiResponseModel<UserParkSiteInfoModel>) => void, reject) => {
            this.userParkSiteApi.GetParkSiteInfo(parkId).subscribe(
                res => {
                    if (res.Data) {
                        this.userParkSiteInfo = res.Data;
                    }
                    resolve(res);
                    new LoaderService().hideFullScreenLoader();
                },
                err => {
                    reject(err);
                    new LoaderService().hideFullScreenLoader();
                }
            );
        });
    }

    public GetParkById(ParkId: string) {
        return new Promise((resolve, reject) => {
            this.userParkSiteApi.GetParkByIdUser(ParkId).subscribe(
                res => {
                    if (res.Data) {
                        this.userParkToEdit = res.Data;
                        if (this.userParkToEdit.ParkFieldsData?.length) {
                            this.userParkToEdit.ParkFieldsDataObj = new ParkSiteFieldsViewModel();

                            this.userParkToEdit.ParkFieldsDataObj = new ParkSiteFieldsViewModel(
                                this.userParkToEdit.ParkFieldsData.map(x => {
                                    return {[`${x.Key}`]: x.Value};
                                }).reduce((r, c) => Object.assign(r, c))
                            );

                            // this.parkToEdit.ParkFieldsData.forEach(obj => {
                            //     Object.keys(this.parkToEdit.ParkFieldsDataObj!).forEach(prop => {
                            //         if (prop === obj.Key) {
                            //             (this.parkToEdit.ParkFieldsDataObj as any)[prop] = obj.Value;
                            //         }
                            //     });
                            // });
                        }
                    }
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public getField(name: string) {
        return this.userParkToEdit.ParkFieldsData?.find(x => x.Key === name);
    }

    // public GetParkImages(id: string) {
    //     return new Promise((resolve, reject) => {
    //         this.userParkSiteApi.GetParkImages(id).subscribe(
    //             res => {
    //                 if (res.Data) {
    //                     this.getParkSiteImages = res.Data!;
    //                 }
    //                 resolve(res);
    //             },
    //             err => {
    //                 reject(err);
    //             }
    //         );
    //     });
    // }
}
