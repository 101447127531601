import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource} from 'axios';
import {Observable, throwError, Subscriber, from} from 'rxjs';
import {catchError, map, delay} from 'rxjs/operators';
import {SdkConfig} from '../sdk.config';
import {AnyObject} from '@/globals';
import {ApiResponseModel, LoginResponseModel} from '../models';
import {ApiAuth, UserSession} from '../core';
import {FilterModel} from '../models/common/filter.model';
import {FilterService} from '../services/shared/filter.service';
import {AccountsApi} from '.';
import {AlertService} from '..';
import https from 'https';

export abstract class BaseApi {
    protected ApiUrl = SdkConfig.ApiPath;
    protected FilterSrv = new FilterService();

    private cancelToken: CancelTokenSource | null = null;

    constructor() {
        this.cancelToken = axios.CancelToken.source();
    }

    private request(method: AxiosRequestConfig['method'], url: string, postBody: AnyObject = {}): Observable<AxiosResponse> {
        const headers: AxiosRequestConfig['headers'] = {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: this.authorize(),
        };

        const agent = new https.Agent({
            rejectUnauthorized: false,
        });

        const options: AxiosRequestConfig = {
            method,
            url,
            headers,
            data: postBody,
            httpsAgent: agent,
        };

        if (this.cancelToken) {
            options.cancelToken = this.cancelToken.token;
        }

        return new Observable((observer: Subscriber<any>) => {
            axios(options)
                .then(res => {
                    // console.log(res);
                    observer.next(res);
                    observer.complete();
                })
                .catch((err: any) => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            map(res => res),
            catchError(e => this.handleError(e))
        );
    }

    private requestAsync<T>(method: AxiosRequestConfig['method'], url: string, postBody: AnyObject = {}) {
        const headers: AxiosRequestConfig['headers'] = {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: this.authorize(),
        };

        const agent = new https.Agent({
            rejectUnauthorized: false,
        });

        const options: AxiosRequestConfig = {
            method,
            url,
            headers,
            data: postBody,
            httpsAgent: agent,
        };

        if (this.cancelToken) {
            options.cancelToken = this.cancelToken.token;
        }

        return axios(options).catch(err => {
            return this.handleErrorAsync(err);
        });
    }

    protected fileRequest(method: AxiosRequestConfig['method'], url: string, data: AnyObject = {}): Observable<AxiosResponse> {
        // Headers to be sent
        const headers: AxiosRequestConfig['headers'] = {
            'Content-Type': 'multipart/form-data',
            Authorization: this.authorize(),
        };
        const options: AxiosRequestConfig = {
            method,
            url,
            headers,
            data,
        };

        return new Observable((observer: Subscriber<any>) => {
            axios(options)
                .then(res => {
                    observer.next(res);
                    observer.complete();
                })
                .catch(err => {
                    observer.error(err);
                    observer.complete();
                });
        }).pipe(
            map(res => res),
            catchError(e => this.handleError(e))
        );
        // return from(axios(options)).pipe(
        //     delay(0),
        //     map(Res => Res),
        //     catchError(e => this.handleError(e))
        // );
    }

    protected downloadRequest(Url: string): Observable<AxiosResponse> {
        const Options: AxiosRequestConfig = {
            method: 'GET',
            url: Url,
            responseType: 'blob',
        };
        return from(axios(Options)).pipe(
            delay(0),
            map(Res => Res),
            catchError(e => this.handleError(e))
        );
    }

    private authorize() {
        const SessionValue = new UserSession().Session;

        return `Bearer ${SessionValue ? SessionValue.JwtToken : ''}`;
    }

    private handleError(error: AxiosError) {
        // console.log(error.response);
        if (error.response?.status === 451) {
            new UserSession().clear()!;
            // new AlertService().show('error', "We can't proceed with your request. You have signed in with another device.").then(() => {
            //     if (router.currentRoute.name !== 'Home') {
            //         router.push({name: 'Home'});
            //     }
            // });
        }
        if (error.response?.status === 401) {
            // this.cancelToken?.cancel();
            // new AlertService().show('info', 'You session is expired. Please login again to continue.').then(() => {});
            // if (router.currentRoute.name !== 'Home') {
            //     router.push({name: 'Home', query: {redirect_reason: 'UNAUTHORIZIED'}});
            // }
        }

        return throwError(() => error?.response?.data ?? 'Internal Server Error');
        // return error?.response?.data ?? 'Internal Server Error';
    }

    private async handleErrorAsync(error: AxiosError) {
        if (error.response?.status === 451) {
            new UserSession().clear()!;
            // new AlertService().show('error', "We can't proceed with your request. You have signed in with another device.").then(() => {
            //     if (window.location.href !== window.location.origin) {
            //         window.location.href = window.location.origin + '?redirect_reason=UNAUTHORIZIED';
            //     }
            // });
        }
        if (error.response?.status === 429) {
            // if (router.currentRoute.name !== 'limitexceeded') {
            //     router.push({name: 'limitexceeded'});
            // }
        }
        if (error.response?.status === 401) {
            this.cancelToken?.cancel();
            // new AlertService().show('info', 'You session is expired. Please login again to continue.').then(() => {});
            // if (router.currentRoute.name !== 'Home') {
            //     // window.location.href = window.location.origin + '?redirect_reason=UNAUTHORIZIED';
            //     router.push({name: 'Home', query: {redirect_reason: 'UNAUTHORIZIED'}});
            // }
        }

        return Promise.reject(error?.response?.data || 'Internal Server Error');
    }

    // Requests
    protected GET_Request<T>(url: string): Observable<ApiResponseModel<T>> {
        return this.request('GET', url).pipe(map(res => res.data));
    }
    protected POST_Request<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.request('POST', url, postBody).pipe(map(res => new ApiResponseModel<T>(res.data)));
    }
    protected Login_POST_Request<T>(url: string, postBody: AnyObject): Observable<LoginResponseModel<T>> {
        return this.request('POST', url, postBody).pipe(map(res => new LoginResponseModel<T>(res.data)));
    }
    protected PATCH_Request<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.request('PATCH', url, postBody).pipe(map(res => res.data));
    }
    protected PUT_Request<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.request('PUT', url, postBody).pipe(map(res => res.data));
    }
    protected DELETE_Request<T>(url: string): Observable<ApiResponseModel<T>> {
        return this.request('DELETE', url).pipe(map(res => res.data));
    }
    protected POST_FileRequest<T>(url: string, postBody: AnyObject): Observable<ApiResponseModel<T>> {
        return this.fileRequest('POST', url, postBody).pipe(map(res => res.data));
    }

    // Async Requests
    protected GET_RequestAsync<T>(url: string, filter: AnyObject = {}): Promise<T> {
        return this.requestAsync<T>('GET', url).then(res => res?.data);
    }
    protected POST_RequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
        return this.requestAsync<T>('POST', url, postBody).then(res => res?.data);
    }
    protected PATCH_RequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
        return this.requestAsync<T>('PATCH', url, postBody).then(res => res?.data);
    }
    protected PUT_RequestAsync<T>(url: string, postBody: AnyObject): Promise<T> {
        return this.requestAsync<T>('PUT', url, postBody).then(res => res?.data);
    }
    protected DELETE_RequestAsync<T>(url: string, postBody?: AnyObject): Promise<T> {
        return this.requestAsync<T>('DELETE', url, postBody).then(res => res?.data);
    }
}
