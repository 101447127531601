import {Component, Prop} from 'vue-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import {AnyObject} from '~/globals';
import {MailerLiteApi, MailerLitePayLoadModel} from '~/sdk';

@Component
export default class PromoCardComponent extends VueWrapper {
    public $refs!: {
        footerBaseform: any;
    };
    @Prop({
        type: Boolean,
        default: false,
    })
    reverse!: boolean;

    @Prop({
        type: String,
        default: 'Access Mobile Home Park Parcel Data',
    })
    title!: string;

    @Prop({
        type: String,
        default:
            "Provide your email and we'll send you a short video that shows how easy we make it for you to view over 50 county parcel data points for each of the mobile home parks and RV parks in our system.",
    })
    description!: string;

    public tempEmail: null | string = null;

    public sources: any = {
        'Access Mobile Home Park Parcel Data': 'parcel-data-form',
        'Manage Mobile Home Park Contacts': 'Contacts-CRM-Form',
        // 'Unlock Our Park Broker Discount': 'Broker-Discount-Form',
        'Target Mobile Home Parks With Precision': 'Park-Sites-Filter-Form',
    };

    public addSubscriber() {
        if (this.tempEmail) {
            this.LoaderSrv.showFullScreenLoader();
            new MailerLiteApi()
                .AddSubscriber(new MailerLitePayLoadModel({Email: this.tempEmail, Fields: {source: this.sources[this.title]}}))
                .subscribe(res => {
                    if (!!res.Data) {
                        this.$refs.footerBaseform.reset();
                        this.tempEmail = '';
                        this.AlertSrv.show('success', "Your email is on its way! Check your spam If it doesn't arrive shortly.");
                        this.LoaderSrv.hideFullScreenLoader();
                    }
                });
        }
    }
}
