import {ServiceClass} from '@/decorators';
import {ParksInAreaSpViewModel} from '@/sdk';
import {ParkSiteViewModel} from '@/sdk/models/park-site';
import {UserParkSiteService} from '@/sdk/services/user-park-site/user-park-site.service';

@ServiceClass()
export class ParkSiteUserSideService {
    public userParkSiteService: UserParkSiteService = new UserParkSiteService();

    public UserParkToMove: ParkSiteViewModel | null = null;

    public includeInactive: boolean = false;

    public userParksList: Array<ParksInAreaSpViewModel> = [];

    constructor() {}
}
