export class KnowledgeBaseModel {
    public Id?: string | null = null;
    public AssignedTo: string | null = null;
    public LookupId: string | null = null;
    public Title: string | null = null;
    public PublishedDate: string | null = null;
    public Data: string | null = null;
    public TabName: string | null = null;
    public Status: boolean = false;

    constructor(data?: KnowledgeBaseModel) {
        Object.assign(this, data);
    }
}
export class ListKnowledgebaseViewModel {
    public Id?: string | null = null;
    public AssignedTo: string | null = null;
    public Lookup: string | null = null;
    public Title: string | null = null;
    public PublishedDate: string | null = null;
    public Data: string | null = null;
    public CreatedBy: string | null = null;
    public CreatedDate: string | null = null;
    public ActiveStatus: string | null = null;

    constructor(data?: ListKnowledgebaseViewModel) {
        Object.assign(this, data);
    }
}
export class UpdateKnowledgebaseViewModel {
    public Id: string | null = null;
    public Status: string | null = null;
    constructor(data?: UpdateKnowledgebaseViewModel) {
        Object.assign(this, data);
    }
}
