import { PaginationModel } from '@/sdk/models';
import { FilterModel } from '@/sdk/models/common/filter.model';
import {
    QuickLinksModel,
    UpdateQuickLinkModel
} from '@/sdk/models/quick-links/quick-links.model';
import { BaseApi } from '../base.api';

export class QuickLinksApi extends BaseApi {
    public getAll(filter?: FilterModel) {
        return this.GET_Request<PaginationModel<QuickLinksModel>>(
            `${this.ApiUrl}/Quicklink/GetAll?filter=${JSON.stringify(filter)}`
        );
    }

    public create(qucikLinksData: QuickLinksModel) {
        return this.POST_Request(`${this.ApiUrl}/Quicklink`, qucikLinksData);
    }

    public getById(id: string) {
        return this.GET_Request<QuickLinksModel>(
            `${this.ApiUrl}/Quicklink/${id}`
        );
    }

    public update(qucikLinksData: QuickLinksModel) {
        return this.PUT_Request(`${this.ApiUrl}/Quicklink`, qucikLinksData);
    }

    public delete(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/Quicklink/${id}`);
    }

    public updateStatus(updateStatus: UpdateQuickLinkModel) {
        return this.PUT_Request(
            `${this.ApiUrl}/Quicklink/changestatus`,
            updateStatus
        );
    }
}
