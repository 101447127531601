import {BlogModel, BlogStatusModel, BlogListingViewModel, PaginationModel, ParkContactModel} from '@/sdk';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {ParkContactStatusModel} from '@/sdk/models/park-contact';
import {BaseApi} from '../base.api';

export class ParkContactAPi extends BaseApi {
    public getAll(filter: FilterModel, parkId: string) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<ParkContactModel>>(`${this.ApiUrl}/ParkContact/GetParkContact/${parkId}?filter=${JSON.stringify(filter)}`);
    }

    public create(parkContactData: ParkContactModel) {
        return this.POST_Request(`${this.ApiUrl}/ParkContact`, parkContactData);
    }

    public getById(id: string) {
        return this.GET_Request<ParkContactModel>(`${this.ApiUrl}/ParkContact/GetContact/${id}`);
    }

    public update(parkContactData: ParkContactModel) {
        return this.PUT_Request(`${this.ApiUrl}/ParkContact`, parkContactData);
    }
    public delete(id: string) {
        return this.DELETE_Request(`${this.ApiUrl}/ParkContact/${id}`);
    }
    public changeStatus(status: ParkContactStatusModel) {
        return this.PUT_Request(`${this.ApiUrl}/ParkContact/ChangeStatus`, status);
    }
}
