import {SaveParkViewModel} from '../park-site';
import {ListingDataModel} from './listing-data.model';
import {BrandingModel} from './park-listing-branding.model';

export class AddParkListingModel {
    public ParkData: SaveParkViewModel | null = null;
    public ParkId: string | null = null;
    public IsParkInfoUpdated: boolean | null = true;
    public ListingBranding: BrandingModel | null = null;
    public ListingData: ListingDataModel | null = null;

    constructor(data?: Partial<AddParkListingModel>) {
        Object.assign(this, data);
    }
}
