import {ChangeMembershipViewModel, MembershipViewModel, StripeCouponModel, StripePromotionCodeModel} from '@/sdk';
import {BaseApi} from '..';

export class PromotionCodeApi extends BaseApi {
    public GetStripePromotionCode(promotionCode: string, membershipId: string = '') {
        return this.GET_Request<StripePromotionCodeModel>(
            `${this.ApiUrl}/PromotionCode/GetStripePromotionCode/${promotionCode}?membershipId=${membershipId ?? ''}`
        );
    }
}
