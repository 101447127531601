import { ServiceClass } from '@/decorators';
import { SettingApi, SettingModel } from '@/sdk';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from '../shared/loader.service';

@ServiceClass()
export class SettingService {
    public Settings = new BehaviorSubject<Array<SettingModel>>([]);
    public getAll(type: string) {
        new LoaderService().showFullScreenLoader();
        new SettingApi()
            .getAll(type)
            .subscribe(({ Data }) => {
                if (Data) {
                    this.Settings.next(Data ?? []);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
