import { ChangePasswordModel } from '@/sdk/models';
import { BaseApi } from '../base.api';

export class ChangePasswordApi extends BaseApi {
    public ChangePassword(changePasswordData: ChangePasswordModel) {
        return this.POST_Request(
            `${this.ApiUrl}/Account/ChangePassword`,
            changePasswordData
        );
    }
}
