import { PaginationModel, SettingModel } from '@/sdk/models';
import { FilterModel } from '@/sdk/models/common/filter.model';
import {
    QuickLinksModel,
    UpdateQuickLinkModel
} from '@/sdk/models/quick-links/quick-links.model';
import { BaseApi } from '../base.api';

export class SettingApi extends BaseApi {
    public getAll(type: string) {
        return this.GET_Request<SettingModel[]>(
            `${this.ApiUrl}/Setting/${type}`
        );
    }

    // public create(qucikLinksData: QuickLinksModel) {
    //     return this.POST_Request(`${this.ApiUrl}/Quicklink`, qucikLinksData);
    // }

    // public getById(id: string) {
    //     return this.GET_Request<QuickLinksModel>(
    //         `${this.ApiUrl}/Quicklink/${id}`
    //     );
    // }

    public update(settingData: Array<SettingModel>) {
        return this.PUT_Request(`${this.ApiUrl}/Setting`, settingData);
    }

    // public delete(id: string) {
    //     return this.DELETE_Request(`${this.ApiUrl}/Quicklink/${id}`);
    // }

    // public updateStatus(updateStatus: UpdateQuickLinkModel) {
    //     return this.PUT_Request(
    //         `${this.ApiUrl}/Quicklink/changestatus`,
    //         updateStatus
    //     );
    // }
}
