import {CRMClaimedParksModel, CRMListModel, CRMModel, PaginationModel, UpdateCRMStatusModel} from '@/sdk';
import {CountModel} from '@/sdk/models/common/count.model';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BaseApi} from '../base.api';

export class CRMApi extends BaseApi {
    public getAll(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<CountModel<CRMListModel>>(`${this.ApiUrl}/CRM/GetAll?filter=${JSON.stringify(filter)}`);
    }

    public getById(id: string) {
        return this.GET_Request<CRMModel>(`${this.ApiUrl}/CRM/${id}`);
    }
    public getClaimedParkById(id: string, filter: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<CRMClaimedParksModel>>(`${this.ApiUrl}/Crm/ContactClaimedParks/${id}?filter=${JSON.stringify(filter)}`);
    }

    public create(crmData: CRMModel, file?: File) {
        const data = new FormData();
        if (crmData.CompanyModel.Id !== null) {
            data.append('Id', crmData.Id!);
        }
        data.append('Address1', crmData.Address1!);
        data.append('Address2', crmData.Address2!);
        data.append('AltPhone', crmData.AltPhone!);
        data.append('Bio', crmData.Bio!);
        data.append('City', crmData.City!);
        data.append('ContactImage', crmData.ContactImage!);
        data.append('Email', crmData.Email!);
        data.append('Facebook', crmData.Facebook!);
        data.append('FirstName', crmData.FirstName!);
        data.append('ImageUrl', crmData.ImageUrl!);
        data.append('LastName', crmData.LastName!);
        data.append('LinkedIn', crmData.LinkedIn!);
        data.append('Note', crmData.Note!);
        data.append('Phone', crmData.Phone!);
        data.append('StateId', crmData.StateId!);
        data.append('Status', JSON.parse(JSON.stringify(crmData.Status!)));
        data.append('TimeZone', crmData.TimeZone!);
        data.append('Twitter', crmData.Twitter!);
        data.append('Youtube', crmData.Youtube!);
        data.append('CompanyViewModel', JSON.stringify(crmData.CompanyModel!));
        data.append('Zip', crmData.Zip!);
        if (crmData.ContactImage) {
            data.append('ContactImage', crmData.ContactImage!);
        }
        if (crmData.CompanyLogo) {
            data.append('CompanyLogo', crmData.CompanyLogo!);
        }

        return this.POST_Request<CRMModel>(`${this.ApiUrl}/CRM`, data);
    }

    public update(crmData: CRMModel) {
        return this.PUT_Request(`${this.ApiUrl}/CRM/changestatus`, crmData);
    }

    public updateStatus(updateStatus: UpdateCRMStatusModel) {
        return this.PUT_Request(`${this.ApiUrl}/CRM/changestatus`, updateStatus);
    }
}
