import {ServiceClass} from '@/decorators';
import {AlertService, LoaderService, ParkListingApi} from '@/sdk';
import {ParkSiteApi} from '@/sdk/api-services/park-site/park-site.api';
import {
    AddParkListingModel,
    GetParkListingImagesModel,
    ListingDataModel,
    BrandingModel,
    ParkListingImagesModel,
    GetAllParkListingModel,
} from '@/sdk/models/park-listing';
import {GetParkSiteImagesModel, ParkSiteFieldsViewModel, ParkSiteImagesModel, SaveParkViewModel} from '@/sdk/models/park-site';

@ServiceClass()
export class SaveParkListingService {
    public parkSiteData: SaveParkViewModel = new SaveParkViewModel();
    public parkListingData = new ListingDataModel();
    public parkListingBranding = new BrandingModel();
    public saveParkListing = new AddParkListingModel();
    public parkListingImages = new ParkListingImagesModel();
    public getParkListingImages = new GetParkListingImagesModel();

    public parkAndListingImagesList: Array<{id?: string | null; file?: File; sequence: number; url: string; uniqueId: string}> = [];
    public parkListingMediaList: Array<{id?: string | null; file?: File; title: string | null; sequence: number; url: string; uniqueId: string}> = [];

    // other properties
    public parkSiteApi: ParkSiteApi = new ParkSiteApi();
    public parkListingApi: ParkListingApi = new ParkListingApi();
    public parkSiteImages: Array<GetParkSiteImagesModel> = [];
    public saveParkSiteImages = new ParkSiteImagesModel();
    public parkListingId: string | null = null;
    public FirstSave: boolean = false;
    public EditButton: boolean = false;
    public DefaultButtons: boolean = false;

    public selectedListing: GetAllParkListingModel | null = null;

    public listingSaved: boolean = false;

    public Show: boolean = false;

    public ListingStatus = [
        {
            key: 'Listing',
            value: 'LISTING',
        },

        {
            key: 'Price Reduced',
            value: 'PRICEREDUCED',
        },

        {
            key: 'Sale Pending',
            value: 'SALEPENDING',
        },
    ];

    public get ParkListingStatus() {
        const parkStatus = this.parkListingData?.Status === 'PUBLISHED' || this.parkListingData?.Status === 'COMPLETED' ? true : false;

        const status =
            this.parkListingData.ParkId && parkStatus
                ? [
                      ...this.ListingStatus,
                      {
                          key: 'Sold',
                          value: 'SOLD',
                      },
                  ]
                : [...this.ListingStatus];

        return status;
    }

    public get ListingStatusesForDialog() {
        let status = this.ListingStatus;

        if (this.selectedListing?.Status === 'PUBLISHED') {
            status = [
                ...this.ListingStatus,
                {
                    key: 'Sold',
                    value: 'SOLD',
                },
            ];
        }

        if (this.selectedListing?.Status === 'COMPLETED') {
            status = [
                {
                    key: 'Sold',
                    value: 'SOLD',
                },
            ];
        }

        return status;
    }

    constructor() {}

    public oldParkData: string | null = null;
    public oldListingData: string | null = null;
    public oldBrandingData: string | null = null;
    // public oldGetParkListingImages: string | null = null;
    public oldListingMedia: string | null = null;
    public oldParkImagesMedia: string | null = null;

    public stringifyParkData() {
        const data = JSON.parse(JSON.stringify(this.parkSiteData));
        const d = new SaveParkViewModel(data);
        d.ParkFieldsData = [];
        return JSON.stringify(d);
    }
    public stringifyBrandingData() {
        if (this.parkListingBranding.IsProfileBranding) {
            return JSON.stringify({
                IsProfileBranding: this.parkListingBranding.IsProfileBranding,
            });
        }
        return JSON.stringify(this.parkListingBranding);
    }

    public stringifyParkListingData() {
        return JSON.stringify(this.parkListingData);
    }

    public stringifyGetParkListingImages() {
        return JSON.stringify(this.getParkListingImages);
    }

    public stringifyListingMedia() {
        return JSON.stringify(this.getParkListingImages.ListingMedia);
    }

    public stringifyParkImagesMedia() {
        return JSON.stringify(this.getParkListingImages.ParkImages);
    }

    public cloneOldParkData() {
        this.oldParkData = this.stringifyParkData();
    }

    public cloneOldListingData() {
        this.oldListingData = this.stringifyParkListingData();
    }

    public cloneListingMedia() {
        this.oldListingMedia = this.stringifyListingMedia();
    }

    // public cloneOldGetParkListingImages() {
    //     this.oldGetParkListingImages = this.stringifyGetParkListingImages();
    // }

    public cloneOldBrandingData() {
        this.oldBrandingData = this.stringifyBrandingData();
    }

    public cloneParkImagesMedia() {
        this.oldParkImagesMedia = this.stringifyParkImagesMedia();
    }

    public get isParkDataEdited() {
        return this.stringifyParkData() !== this.oldParkData;
    }
    public get isListingDataEdited() {
        return this.stringifyParkListingData() !== this.oldListingData;
    }
    public get isBrandingDataEdited() {
        return this.stringifyBrandingData() !== this.oldBrandingData;
    }

    public get isParkImagesMediaEdited() {
        return (
            this.stringifyParkImagesMedia() !== this.oldParkImagesMedia ||
            (this.parkListingImages.ParkImages?.length ?? 0) > 0 ||
            !!this.parkListingImages.BrandingUserPicture ||
            !!this.parkListingImages.BrandingCompanyLogo
        );
    }

    public get isListingMediaEdited() {
        return this.stringifyListingMedia() !== this.oldListingMedia || (this.parkListingImages.ListingDocuments?.length ?? 0) > 0;
    }

    // public get isGetParkListingImagesEdited() {
    //     return this.stringifyGetParkListingImages() !== this.oldGetParkListingImages;
    // }

    public get isEdited() {
        return this.isParkDataEdited || this.isListingDataEdited || this.isBrandingDataEdited || this.isListingMediaEdited || this.isParkImagesMediaEdited;
    }

    public get disableSave() {
        return !this.isEdited;
    }

    public get ParkListing() {
        if (!this.saveParkListing.ParkId) {
            this.saveParkListing.ParkId = this.parkSiteData.Id!;
            this.parkListingData.ParkId = this.parkSiteData.Id!;
        }
        this.saveParkListing.ListingBranding = this.parkListingBranding;
        this.saveParkListing.ParkData = this.parkSiteData;
        this.saveParkListing.ListingData = this.parkListingData;
        return this.saveParkListing;
    }
    public set ParkListing(data: AddParkListingModel) {
        this.saveParkListing.ParkId = data.ParkId;
        this.parkListingData.ParkId = data.ParkId;
        this.parkListingBranding = data.ListingBranding!;
        this.parkSiteData = data.ParkData!;
        this.parkListingData = data.ListingData!;

        if (this.parkSiteData.ParkFieldsDataObj && this.parkSiteData.ParkFieldsDataObj?.TotalMHLots !== null) {
            this.parkSiteData.ParkFieldsDataObj.TotalMHLots = Number(this.parkSiteData.ParkFieldsDataObj.TotalMHLots ?? '0');
        }

        if (this.parkSiteData.ParkFieldsDataObj && this.parkSiteData.ParkFieldsDataObj?.TotalRVLots !== null) {
            this.parkSiteData.ParkFieldsDataObj.TotalRVLots = Number(this.parkSiteData.ParkFieldsDataObj.TotalRVLots ?? '0');
        }

        this.cloneOldParkData();
        this.cloneOldListingData();
        this.cloneOldBrandingData();
    }

    public get ParkId() {
        return this.parkSiteData.Id;
    }

    public stepOneCompleted: boolean = false;
    public stepTwoCompleted: boolean = false;
    public stepThreeCompleted: boolean = false;
    public stepFourCompleted: boolean = false;

    public get isParkSitesStepVisible() {
        return !!this.parkListingId;
    }

    public init() {
        this.parkSiteData = new SaveParkViewModel();
        this.parkListingData = new ListingDataModel();
        this.parkListingBranding = new BrandingModel();
        this.saveParkListing = new AddParkListingModel();
        this.parkListingImages = new ParkListingImagesModel();
        this.getParkListingImages = new GetParkListingImagesModel();

        // other properties
        this.parkSiteApi = new ParkSiteApi();
        this.parkListingApi = new ParkListingApi();
        this.parkSiteImages = [];
        this.saveParkSiteImages = new ParkSiteImagesModel();
        this.parkListingId = null;
        this.FirstSave = false;
        this.EditButton = false;
        this.DefaultButtons = false;
    }

    public GetParkById(ParkId: string) {
        this.parkSiteApi.GetParkById(ParkId).subscribe(res => {
            if (res.Data) {
                this.parkSiteData = new SaveParkViewModel(res.Data);
                if (this.parkSiteData.ParkFieldsData?.length) {
                    this.parkSiteData.ParkFieldsDataObj = new ParkSiteFieldsViewModel(
                        this.parkSiteData.ParkFieldsData.map(x => {
                            if (x.Value != null) {
                                return {[`${x.Key}`]: x.Value};
                            }
                            return {};
                        }).reduce((r, c) => Object.assign(r, c))
                    );
                }
            }
        });
    }

    public GetParkImages(id: string) {
        this.parkSiteApi.GetParkImages(id).subscribe(res => {
            if (res.Data) {
                this.parkSiteImages = res.Data!;

                // if (!this.parkListingId) {
                //     const images =
                //         this.parkSiteImages?.map(x => {
                //             return {
                //                 id: x.Id,
                //                 sequence: x.Sequence ? +x.Sequence : 0,
                //                 url: x.Url!,
                //                 uniqueId: x.Id!
                //             };
                //         }) ?? [];

                //     this.parkAndListingImagesList.push(...images);
                // }
            }
        });
    }

    public getById(id: string) {
        return new Promise((resolve, reject) => {
            try {
                new LoaderService().showFullScreenLoader();

                new ParkListingApi()
                    .GetParkListingById(id)
                    .subscribe(({Data}) => {
                        if (Data) {
                            this.ParkListing = Data;
                            // console.log(this.parkSiteData.ParkFieldsDataObj);
                            if (this.parkSiteData.ParkFieldsData?.length) {
                                this.parkSiteData.ParkFieldsData = this.parkSiteData.ParkFieldsData!.map(x => {
                                    if (x.FieldType === 'Dropdown') {
                                        x.Value = x.Items?.find(key => {
                                            return x.Value === key.Key;
                                        })?.Value as any;
                                    }
                                    return x;
                                });

                                this.parkListingImages.OldIsProfileBranding = this.parkListingBranding.IsProfileBranding;
                                if (this.parkListingBranding.IsProfileBranding && !!this.parkListingId) {
                                    this.parkListingBranding.CompanyLogo = null;
                                    this.parkListingBranding.ProfilePicture = null;
                                }
                            }
                            resolve(Data);
                        }
                    })
                    .add(() => {
                        new LoaderService().hideFullScreenLoader();
                    });
            } catch (e) {
                reject(e);
            }
        });
    }

    public getListingImagesById(id: string) {
        new LoaderService().showFullScreenLoader();
        return new Promise(resolve => {
            new ParkListingApi()
                .GetParkListingImages(id)
                .subscribe(
                    res => {
                        if (res.Data) {
                            this.getParkListingImages = res.Data;
                            this.parkAndListingImagesList.push(
                                ...(this.getParkListingImages.ParkImages?.map(x => {
                                    return {
                                        id: x.Id,
                                        sequence: x.Sequence ? +x.Sequence : 0,
                                        url: x.Url!,
                                        uniqueId: x.Id!,
                                    };
                                }) ?? [])
                            );

                            this.parkListingMediaList =
                                this.getParkListingImages.ListingMedia?.map(x => {
                                    return {
                                        id: x.Id,
                                        sequence: x.Sequence ? +x.Sequence : 0,
                                        url: x.Url!,
                                        title: x.Title,
                                        uniqueId: x.Id!,
                                    };
                                }) ?? [];

                            this.cloneListingMedia();
                            this.cloneParkImagesMedia();
                        }
                    },
                    error => {
                        new AlertService().show('error', 'Something went wrong');
                    }
                )
                .add(() => {
                    new LoaderService().hideFullScreenLoader();
                    resolve(null);
                });
        });
    }

    public getField(name: string) {
        return this.parkSiteData.ParkFieldsData?.find(x => x.Key === name);
    }
}
