export class ParkSiteImagesModel {
    public ParkId?: string | null = null;
    public DeletedImageIds: Array<string> | null = null;
    public OldImagesIds: Array<{Id: string; Sequence: number}> | null = null;
    public Sequences: Array<number> | null = null;
    public Images: Array<File> | null = null;

    constructor(data?: Partial<ParkSiteImagesModel>) {
        Object.assign(this, data);
    }
}

export class GetParkSiteImagesModel {
    public Id: string | null = null;
    public Url: string | null = null;
    public Sequence: number | null = null;

    constructor(data?: Partial<GetParkSiteImagesModel>) {
        Object.assign(this, data);
    }
}
