export class ParkLisitingViewModel {
    public Title: string | null = null;
    public AdId: string | null = null;
    public Created: string | null = null;
    public ListingId: string | null = null;
    public AdType: string | null = null;
    public AdTypeStr: string | null = null;
    public ParkName: string | null = null;
    public SecondaryTitle: string | null = null;
    public State: string | null = null;
    public Latitude: number | null = null;
    public Longitude: number | null = null;
    public Thumbnail: string | null = null;
    public AskingPrice: number | null = null;
    public UnPriced: boolean | null = null;
    public OffMarket: boolean | null = null;
    public UniqueId: number | null = null;
    public ListingStatusStr: string | null = null;
    public MhLotsIncluded: boolean | null = null;
    public RVLotsIncluded: boolean | null = null;
    public SoldStatus: boolean | null = null;
    public IsFeatured: boolean | null = null;
    public IsFavorite: boolean | null = null;
    public AdUrl: string | null = null;
    public BrandingCompanyLogo: string | null = null;

    constructor(data?: Partial<ParkLisitingViewModel>) {
        Object.assign(this, data);
    }
}
