export class NotificationModel {
    public Id: string | null = null;
    public LookupId: string | null = null;
    public StartDate: string | null = null;
    public Title: string | null = null;
    public Description: string | null = null;
    public Data: string | null = 'null';
    public Status: boolean | null = false;

    constructor(data?: Partial<NotificationModel>) {
        Object.assign(this, data);
    }
}

export class ListNotificationModel {
    public Id?: string | null = null;
    public StartDate: string | null = null;
    public Lookup: string | null = null;
    public Title: string | null = null;
    public Description: string | null = null;
    public SubmitedBy: string | null = null;
    public SubmitedDate: string | null = null;
    public ActiveStatus: string | null = null;

    constructor(data?: Partial<ListNotificationModel>) {
        Object.assign(this, data);
    }
}

export class UpdateNotificationModel {
    public Id: string | null = null;
    public Status: string | null = null;

    constructor(data?: Partial<UpdateNotificationModel>) {
        Object.assign(this, data);
    }
}
