import {ServiceClass} from '@/decorators';
import {MembershipTypes, UserMembershipStatus} from '@/globals';
import {AccountsApi} from '@/sdk/api-services';
import {UsersApi} from '@/sdk/api-services/users/users.api';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {UsersModel, RoleUsersModel} from '@/sdk/models/users/users.model';
import {BehaviorSubject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {AlertService} from '../shared/alert.service';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class UsersService {
    public Users = new BehaviorSubject<Array<UsersModel>>([]);
    public RoleUsers = new BehaviorSubject<Array<RoleUsersModel>>([]);
    public User$ = new BehaviorSubject(new UsersModel());
    public loggedInUser$ = new BehaviorSubject(new UsersModel());
    public Filter = new FilterModel();
    TotalRows: number = 0;
    public options: DataOptions = {} as DataOptions;
    public premiumRouteNames = ['User Dashboard', 'Park Site', 'Classifieds', 'CRM'];

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    public reset() {
        this.User$.next(new UsersModel());
    }

    public get membershipTypes() {
        return MembershipTypes;
    }

    public get currentPlan() {
        if (this.User$.value?.UserMemberships?.length) {
            return this.User$.value.UserMemberships.find(x => x.Status === UserMembershipStatus.ACTIVE);

            // if (this.User$.value.UserMemberships.length > 1 && this.User$.value.UserMemberships[0].MembershipSlug === MembershipTypes.BasicMembership) {
            //     return this.User$.value.UserMemberships[1];
            // }
            // return this.User$.value.UserMemberships[0];
        }
        // return this.User$.value?.UserMemberships?.find(x => x.Status === ).MembershipSlug;
        return null;
    }

    public get inUsePlan() {
        return this.previousPlan ?? this.currentPlan ?? null;
    }

    public get previousPlan() {
        if (this.User$.value?.UserMemberships?.length) {
            return this.User$.value.UserMemberships.find(x => x.Status === UserMembershipStatus.CANCELLED_SOON);
        }

        return null;
    }

    public get futurePlan() {
        if (this.User$.value?.UserMemberships?.length) {
            return this.User$.value.UserMemberships.find(x => x.Status === UserMembershipStatus.FUTURE);
        }

        return null;
    }

    public get currentMembershipTitle() {
        return this.previousPlan?.MembershipTitle ?? this.currentPlan?.MembershipTitle ?? '';
    }

    public get currentExpires() {
        return this.previousPlan?.CurrentPeriodEnd ?? this.currentPlan?.CurrentPeriodEnd ?? '';
    }

    public get nextIsBasic() {
        if (this.User$.value?.UserMemberships?.length) {
            return this.User$.value.UserMemberships.length > 1 && this.User$.value.UserMemberships[0].MembershipSlug === MembershipTypes.BasicMembership;
        }

        return false;
    }

    public get IsBasicUser() {
        return !this.previousPlan && this.currentPlan?.MembershipSlug === MembershipTypes.BasicMembership;
    }
    public get IsPremiumUser() {
        return (
            this.currentPlan?.MembershipSlug === MembershipTypes.PremiumMembership ||
            this.currentPlan?.MembershipSlug === MembershipTypes.AnnualPremiumMembership ||
            this.previousPlan?.MembershipSlug === MembershipTypes.PremiumMembership ||
            this.previousPlan?.MembershipSlug === MembershipTypes.AnnualPremiumMembership
        );
    }
    public get IsCancelledUser() {
        return this.currentPlan?.MembershipSlug === MembershipTypes.CancelledMembership;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;

        this.getAll();
    };

    public isPremiumRoute(routeName: string): boolean {
        const route = this.premiumRouteNames.some(premiumRoute => premiumRoute === routeName);
        return route;
    }

    public getAllAdmins() {
        new LoaderService().showFullScreenLoader();
        this.Filter.Query = 'admin';
        this.Filter.PageSize = -1;
        new UsersApi().getAll(this.Filter).subscribe(
            ({Data}) => {
                new LoaderService().hideFullScreenLoader();
                this.Users.next(Data?.Data ?? []);
                // if (this.Filter.Status !== 'FALSE') {
                //     this.TotalRows = Data?.TotalRows! - 1 || 0;
                // } else {
                //     this.TotalRows = Data?.TotalRows || 0;
                // }
                this.TotalRows = Data?.TotalRows || 0;
            },
            () => {
                new LoaderService().hideFullScreenLoader();
            }
        );
    }

    public getAll() {
        new LoaderService().showFullScreenLoader();
        new UsersApi().getAll(this.Filter).subscribe(
            ({Data}) => {
                new LoaderService().hideFullScreenLoader();
                this.Users.next(Data?.Data ?? []);
                // if (this.Filter.Status !== 'FALSE') {
                //     this.TotalRows = Data?.TotalRows! - 1 || 0;
                // } else {
                //     this.TotalRows = Data?.TotalRows || 0;
                // }
                this.TotalRows = Data?.TotalRows || 0;
            },
            () => {
                new LoaderService().hideFullScreenLoader();
            }
        );
    }
    public getByRole(role: string) {
        new LoaderService().showFullScreenLoader();
        new UsersApi().getByRole(role).subscribe(
            ({Data}) => {
                new LoaderService().hideFullScreenLoader();
                this.RoleUsers.next(Data ?? []);
            },
            () => {
                new LoaderService().hideFullScreenLoader();
            }
        );
    }

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();

        new UsersApi()
            .getById(id)
            .subscribe(({Data}) => {
                if (Data) {
                    this.User$.next(Data);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }

    public loadLoggedInUser(id: string) {
        new LoaderService().showFullScreenLoader();

        new UsersApi()
            .getById(id)
            .subscribe(({Data}) => {
                if (Data) {
                    this.loggedInUser$.next(Data);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
