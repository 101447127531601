import {
    PaginationModel,
    StateModel,
    UpdateStateStatusModel
} from '@/sdk/models';
import { FilterModel } from '@/sdk/models/common/filter.model';
import { BaseApi } from '../base.api';

export class StateApi extends BaseApi {
    public getAllStates(filter?: FilterModel) {
        if (!filter) {
            filter = new FilterModel();
        }
        return this.GET_Request<PaginationModel<StateModel>>(
            `${this.ApiUrl}/State?filter=${JSON.stringify(filter)}`
        );
    }

    public getById(id: string) {
        return this.GET_Request<StateModel>(`${this.ApiUrl}/State/${id}`);
    }

    public update(stateData: StateModel) {
        return this.PUT_Request(`${this.ApiUrl}/State`, stateData);
    }

    public updateStatus(updateStatus: UpdateStateStatusModel) {
        return this.PUT_Request(
            `${this.ApiUrl}/State/changestatus`,
            updateStatus
        );
    }
}
