export class QuickLinksModel {
    public Id?: string | null = null;
    public Title: string | null = null;
    public Url: string | null = null;
    public ActiveStatus: string | null = null;
    public Active: boolean = false;

    constructor(data?: QuickLinksModel) {
        Object.assign(this, data);
    }
}

export class UpdateQuickLinkModel {
    public Id: string | null = null;
    public Status: string | null = null;
    constructor(data?: UpdateQuickLinkModel) {
        Object.assign(this, data);
    }
}
