export class ContactSellerModel {
    public Name: string | null = null;
    public Email: string | null = null;
    public SendNotification: boolean | null = false;
    public Message: string | null = null;
    public AdId: string | null = null;

    constructor(data?: Partial<ContactSellerModel>) {
        Object.assign(this, data);
    }
}

export class ContactParkforSaleSellerModel{
    public Name: string | null = null;
    public Email: string | null = null;
    public SendNotification: boolean | null = false;
    public Message: string | null = null;
    public AdId: string | null = null;
    public ListingId : string|null = null;

    constructor(data?: Partial<ContactParkforSaleSellerModel>) {
        Object.assign(this, data);
    }
}
