import {ImagesDataModel} from './get-park-listing-images.model';

export class BrandingModel {
    public Id: string | null = null;
    public CompanyName: string | null = null;
    public CompanyLogo: ImagesDataModel | null = null;
    public Name: string | null = null;
    public StreetAddress: string | null = null;
    public City: string | null = null;
    public DirectPhone: string | null = null;
    public OfficePhone: string | null = null;
    public IsProfileBranding: boolean | null = true;
    public ProfilePicture: ImagesDataModel | null = null;

    constructor(data?: Partial<BrandingModel>) {
        Object.assign(this, data);
        // this.ProfilePicture = new ImagesDataModel(data?.ProfilePicture ?? {});
        // this.CompanyLogo = new ImagesDataModel(data?.CompanyLogo ?? {});
    }
}
