import {ServiceClass} from '@/decorators';
import {ListNotificationModel, NotificationApi, NotificationModel} from '@/sdk';
import {KnowledgeBaseApi} from '@/sdk/api-services/knowledge-base/knowledge-base.api';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DataOptions} from 'vuetify';
import {LoaderService} from '../shared/loader.service';

@ServiceClass()
export class NotificationService {
    public Notifications = new BehaviorSubject<Array<ListNotificationModel>>([]);
    public UserNotifications = new BehaviorSubject<Array<ListNotificationModel>>([]);
    public Notification = new BehaviorSubject(new NotificationModel());
    public Filter: FilterModel = new FilterModel();
    public TotalRows: number = 0;
    public Count: Subject<number> = new Subject<number>();
    public options: DataOptions = {} as DataOptions;

    constructor() {
        this.options.itemsPerPage = this.Filter.PageSize!;
    }

    FilterData = (event: DataOptions) => {
        this.Filter.PageNo = event.page;
        this.Filter.PageSize = event.itemsPerPage;
        this.Filter.SortBy = event.sortBy.length ? event.sortBy[0] : null;
        this.Filter.SortDesc = event.sortDesc.length ? event.sortDesc[0] : false;
        this.getAll();
    };

    public getAll() {
        new LoaderService().showFullScreenLoader();
        new NotificationApi()
            .getAll(this.Filter)
            .subscribe(({Data}) => {
                if (Data) {
                    this.Notifications.next(Data.Data ?? []);
                    this.TotalRows = Data?.TotalRows || 0;
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
    public getUserNotifications(loader: boolean = true) {
        if (loader) {
            new LoaderService().showFullScreenLoader();
        }
        new NotificationApi()
            .getUserNotifications(this.Filter)
            .subscribe(({Data}) => {
                if (Data) {
                    this.UserNotifications.next(Data.Data ?? []);
                    this.Count.next(Data.Count);
                }
            })
            .add(() => {
                if (loader) {
                    new LoaderService().hideFullScreenLoader();
                }
            });
    }

    public getById(id: string) {
        new LoaderService().showFullScreenLoader();

        new NotificationApi()
            .getById(id)
            .subscribe(({Data}) => {
                if (Data) {
                    this.Notification.next(Data);
                }
            })
            .add(() => {
                new LoaderService().hideFullScreenLoader();
            });
    }
}
