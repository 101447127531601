import {Component, Prop} from 'vue-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import {AnyObject} from '~/globals';

@Component
export default class ExploreCardComponent extends VueWrapper {
    @Prop({
        type: Object,
        required: true,
    })
    item!: {title: string; desc: string; link: string;  more: string,desc2?:string};
}
